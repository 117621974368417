.TopBar {
    border-bottom: 1px solid var(--border-light);
    z-index: 10;
    position: relative;
    padding: 0 24px;

    .enabled-preview:hover {
        border: 1px solid var(--anchor-text-color);
        background-color: var(--light-sky-blue);
    }

    .disabled-preview {
        background: var(--very-light-gray);
        filter: opacity(0.5);
    }

    .screen-preview {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        height: 58px;
        width: 58px;
        border: 1px solid var(--very-light-gray);
        border-radius: 32px;
        margin-right: 15px;
        cursor: pointer;

        .screen-preview-icon {
            height: 25px;
            width: 25px;
        }

        .screen-preview-text {
            color: var(--dark-slate-blue);
            font-family: 'Cabin', sans-serif;
            font-size: 8px;
        }
    }

    .content {
        height: 102px;
        justify-content: center;
        display: flex;

        .left-content, .right-content {
            display: flex;
            align-items: center;
        }

        .left-content {
            margin-right: auto;
            text-decoration: none;

            .beta-text {
                margin-left: 8px;
                font-size: 12px;
                color: var(--dark-sky-blue);
                cursor: default;
            }

            .beta-switch {
                display: flex;
                align-items: center;
                background-color: var(--white-two);
                // width: 133px;
                width: 143px;
                height: 33px;
                border-radius: 17px;
                font-size: 10px;
                line-height: 13px;
                margin-left: 22px;

                .classic {
                    width: 100%;
                    margin-left: 13px;
                    color: var(--dark-slate-blue);
                    opacity: 0.5;
                    cursor: default;
                }

                .beta {
                    width: 100%;
                    color: var(--dark-sky-blue);
                    margin-left: -7px;
                    cursor: default;
                }

                .barColor {
                    background-color: var(--dark-slate-blue);
                    opacity: 1;
                }
            }
        }

        .right-content {
            justify-content: flex-end;
            .circle-button {
                height: 55px;
                width: 55px;
                border: 2px solid var(--very-light-gray);
                color: var(--dark-slate-blue);
                margin-right: 12px;
            }

            .top-menu {
                width: 207px;
                height: 61px;
                border-radius: 6px;
                border: solid 0.5px #707070;
                display: flex;
                align-items: center;
                cursor: pointer;

                &:hover {
                    border: solid 0.5px var(--dark-sky-blue);
                }

                .avatar {
                    padding: 9px;
                    width: 43px;
                    height: 43px;
                    border-radius: 30px;
                    object-fit: cover;
                }

                .middle {
                    color: var(--dark-slate-blue);
                    width: 116px;
                    overflow: hidden;
                    padding-top: 3px;

                    .organization {
                        font-family: 'Cabin', sans-serif;
                        height: 17px;
                        font-size: 13px;
                        font-weight: bold;
                        line-height: 1.23;
                        text-transform: capitalize;
                        width: 116px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }

                    .username {
                        font-family: 'Cabin', sans-serif;
                        height: 17px;
                        font-size: 10px;
                        line-height: 1.3;
                        max-width: 135px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }

                .right {
                    height: 27px;
                    width: 27px;
                    margin-right: 3px;
                    .opened {
                        color: var(--dark-sky-blue);
                    }
                }
            }

        }
    }
}

.UserMenu {
    margin-top: 20px;

    .MuiPaper-root {
        border-radius: 24px;
    }

    .content {
        box-sizing: border-box;
        width: 444px;
        display: flex;
        flex-direction: column;
        max-height: 624px;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        background-color: var(--white);
        padding: 20px;
        font-family: 'Cabin', sans-serif;

        .top {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            
            .left {
                margin-right: 8px;
                display: block;
            }

            .middle {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            .right {
                margin-left: auto;
                display: flex;
                align-items: center;
                gap: 10px;

              a {
                  display: flex;
                  align-items: center;
              }
            }

            .name-container {
                font-family: Cabin;
                font-size: 14px;
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                text-align: left;
                color: var(--dark-slate-blue);
                width: 280px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 4px;

                .name {
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .org-id {
                font-family: Cabin, sans-serif;
                font-size: 14px;
                color: var(--brownish-grey-50);
            }

            .avatar {
                width: 45px;
                height: 45px;
                border-radius: 30px;
                object-fit: cover;
            }

            a, button {
                color: var(--text-color-over-light);
                margin: 0;
                padding: 0;
                min-width: 0;
            }

            .edit {
                color: var(--dark-sky-blue);
                margin-left: 5px;
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            overflow-y: auto;

            .content-account {
                display: flex;
                flex-direction: column;
            }

            .OrganizationName {
                height: 21px;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.25;
                color: var(--dark-slate-blue);
                display: flex;
                align-items: center;

                .small_link {
                    margin-left: 5px;
                }

                .launch {
                    margin-left: 3px;
                    color: var(--dark-sky-blue);
                }
            }

            .buttons {
                margin-top: 26px;
                display: flex;
                justify-content: space-between;

                button {
                    border-radius: 22px;
                    height: 44px;
                    padding: 0 25px;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.67;
                    color: var(--brownish-grey);
                    background-color: var(--white-two);
                    font-family: 'Cabin', sans-serif;
                }
            }
        }


        .small_link {
            font-size: 8px;
            color: var(--dark-sky-blue);
            text-decoration: underline;
            font-family: 'Cabin', sans-serif;
        }

        .small_text_10 {
            font-family: 'Cabin', sans-serif;
            line-height: 20px;
            font-size: 10px;
            color: var(--brownish-grey-50);
        }
    }
}

.changeAccountModal {
    width: 100%;
    display: flex;
    justify-content: center;
    
    .content-account {
        margin: 0;
        width: 100%;

        .close {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.88;
            color: var(--white);
            position: absolute;
            top: -32px;
            right: 5px;
            text-decoration: underline;

            &:focus {
                outline: none;
            }
        }

        .header {
            display: flex;

            .title {
                font-family: 'Cabin', sans-serif;
                font-size: 24px;
                font-weight: bold;
                line-height: 0.83;
                color: var(--dark-slate-blue);
            }

            .switch_link {
                margin-left: 10px;
                font-size: 8px;
                line-height: 1.25;
                color: var(--brownish-grey);

                span {
                    font-weight: bold;
                    color: var(--dark-sky-blue);
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .search {
            .MuiFormControl-root {
                box-sizing: border-box;
                margin-top: 0;
                border: 1px solid #E4E4E5;
                border-radius: 4px;
            }
            .MuiOutlinedInput-notchedOutline {
                border: none;
            }
        }

        input {
            margin-top: 0 !important;
            font-family: 'Cabin', sans-serif;
            font-size: 14px;
            padding: 10px 16px;
            color: var(--dark-slate-blue);
        }

        .org-tree-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #21445E;
            margin: 12px 0;

            h2 {
                font-size: 16px;
                margin: 0
            }

            .checkbox-container {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: #656979;

                .MuiButtonBase-root {
                    padding: 8px
                }
            }
        }

        .scrollableView {
            overflow-y: auto;
            display: flex;
            border-radius: 12px;
            border: 1px solid #E4E4E5;

            .orgDivider{
                background-color:rgb(240, 238, 238);
                color: rgba(0, 0, 0, 0.75);
                display: block;
                margin-bottom: 12px;
                height: 1px;
                width: 317px;
            }

            .saved {
                padding: 0;

                .title {
                    font-family: 'Cabin', sans-serif;
                    width: 102px;
                    height: 23px;
                    font-size: 9px;
                    line-height: 1.22;
                    padding-left: 0;
                    color: var(--brownish-grey);
                }
            }

            .all {
                .title {
                    font-family: 'Cabin', sans-serif;
                    padding-top: 25px;
                    width: 102px;
                    height: 23px;
                    font-size: 9px;
                    color: var(--brownish-grey);
                }
            }
        }
    }
}

