.Home {
    flex: 1;
    display: flex;

    .page-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

}

.loading-page {
    position: fixed;
    background: white;
    z-index: 200;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}