.breadcrumb{
    height: 40px;
    width: auto;
    display: flex;
    align-items: center;
    background-color: var(--white);

    .breadcrumbItem{
        height: 36px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        text-align: center;
        border: solid 1px var(--light-sky-blue);
        background-color: var(--light-sky-blue);
        font-family: Cabin;
        font-size: 12px;
        font-weight: 600;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: var(--dark-slate-blue);

        .org-name {
            text-align: left;
            width: 130px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .current-org-name {
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: 120px;
            white-space: nowrap;
        }

        a, a:link, a:visited, a:active{
            color: var(--dark-slate-blue);
            text-decoration: none;
            > div {
                max-width: 50px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        a:hover {
            color: var(--anchor-text-color);
            text-decoration: none;
        }

        .bussiness-icon {
            color: var(--dark-slate-blue);
        }

        .dropdown {
            position: relative;
            display: flex;
            flex-direction: column;
            align-content: left;
       }
    }

    .selected {
        color: var(--white);
        border: solid 1px var(--dark-sky-blue);
        background-color: var(--dark-sky-blue);
        padding-right: 25px;
        cursor: default;

        button {
            background-color: var(--dark-sky-blue);
        }

        .whiteLink:link, .whiteLink:visited, .whiteLink:hover, .whiteLink:active {
            color: var(--white);
        }
    }

    .removePadd {
        padding-right: 0;
    }

    .triangle{
        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-left: 15px solid var(--light-sky-blue);
        border-bottom: 18px solid transparent;
        margin: 0 5px 0 0;
    }

    .triangleSelected{
        width: 0;
        height: 0;
        border-top: 18px solid transparent;
        border-left: 15px solid var(--dark-sky-blue);
        border-bottom: 18px solid transparent;
        margin: 0 5px 0 0;
    }

    .tail{
        display: flex;
        flex-direction: column;
        margin-left:-15px;
    }

    .tailUp{
        width: 0;
        height: 0;
        border-top: 19px solid var(--light-sky-blue);
        border-left: 15px solid transparent;
    }

    .tailUpSelected{
        width: 0;
        height: 0;
        border-top: 19px solid var(--dark-sky-blue);
        border-left: 15px solid transparent;
    }

    .tailDown{
        width: 0;
        height: 0;
        border-bottom: 19px solid var(--light-sky-blue);
        border-left: 15px solid transparent;
    }

    .tailDownSelected{
        width: 0;
        height: 0;
        border-bottom: 19px solid var(--dark-sky-blue);
        border-left: 15px solid transparent;
    }
}