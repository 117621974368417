.OrganizationTree {
    width: 100%;

    .loading-list {
        padding: 0;

        li {
            display: block;
            margin: 16px 0;
            height: 16px;
            width: 150px;
            list-style: none;
            background-color: #eee;
            border-radius: 10px;

            &:nth-child(odd) {
                width: 120px;
            }

            &:nth-last-child(1), &:nth-last-child(2) {
                margin-left: 20px;
            }
        }
    }

    .child-list-container {
        padding: 0
    }

    .OrgListItem {
        font-family: Cabin, sans-serif;
        font-size: 16px;
        color: #21445E;
        margin: 0;
        padding: 12px;
        border-bottom: 1px solid #E4E4E5;

        &.first-level {
            &:last-child {
                border: none
            }
        }

        &.empty-state {
            border: none;
        }

        &.selected {
            background-color: #EBF6FC;

            .itemContent {
                a,.check {
                    color: #288BB9;
                }
            }
        }

        .itemContent {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #21445E;

            .left {
                max-width: 92%;
                display: flex;
                align-items: center;
                gap: 8px;
            }

            a {
                color: #21445E;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .star {
                cursor: pointer;
                margin-left: auto;
                font-size: 20px;
                color: var(--dark-sky-blue);
            }
        }
    }
}
