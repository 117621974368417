.EmptyState {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    &.no_results {
        margin-top: 32px;
    }

    .title {
        font-size: 24px;
        color: var(--dark-slate-blue);
        font-weight: 600;
        margin-bottom: 0;
        text-align: center;
    }

    .message {
        font-size: 14px;
        color: var(--dark-slate-blue-60);
        width: 356px;
        text-align: center;
        line-height: 21px;
        margin: 16px 0;
    }

    .buttons-container {
        display: flex;

        .button {
            justify-content: flex-start;
            height: 34px;
            margin: 0 8px;
            border-radius: 17px;
            font-size: 12px;
            font-weight: 600;
            text-transform: none;
            box-shadow: none;
            border: 2px solid var(--dark-sky-blue);

            &.secondary {
                box-sizing: border-box;
                padding: 0 16px;
                border: 2px solid var(--dark-sky-blue);
                color: var(--dark-slate-blue);
                letter-spacing: 0.1px;
                margin-left: 12px;
            }
        }
    }

    .help-guides {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 8px;
        font-size: 12px;
        color: var(--dark-slate-blue-60);

        a {
            margin-left: 6px;
            display: flex;
            align-items: center;
            flex-direction: row;

            svg {
                margin-left: 3px;
                width: 12px;
            }
        }
    }

    .version {
        font-size: 12px;
        color: var(--dark-slate-blue-60);
        width: 356px;
        text-align: center;
        line-height: 16px;
        margin-top: 16px;
    }
}
