.everpass {

    @font-face {
        font-family: "Roobert Regular";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-Regular.otf");
    }

    @font-face {
        font-family: "Roobert Light";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-Light.otf");
    }

    @font-face {
        font-family: "Roobert Medium";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-Medium.otf");
    }

    @font-face {
        font-family: "Roobert SemiBold";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-SemiBold.otf");
    }

    @font-face {
        font-family: "Roobert Bold";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-Bold.otf");
    }

    @font-face {
        font-family: "Roobert Heavy";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-Heavy.otf");
    }

    @font-face {
        font-family: "Roobert Regular Italic";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-RegularItalic.otf");
    }

    @font-face {
        font-family: "Roobert Regular Italic";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-LightItalic.otf");
    }

    @font-face {
        font-family: "Roobert Medium Italic";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-MediumItalic.otf");
    }

    @font-face {
        font-family: "Roobert SemiBold Italic";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-SemiBoldItalic.otf");
    }

    @font-face {
        font-family: "Roobert Bold Italic";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-BoldItalic.otf");
    }

    @font-face {
        font-family: "Roobert Heavy Italic";
        src: url("https://cdn.upshow.tv/assets/fonts/Roobert-HeavyItalic.otf");
    }


    --black: #000000;
    --green-everpass-10:#DBE9E8;
    --green-everpass-20: #A9CBC5;
    --green-everpass-40: #4f8f80;
    --green-everpass-70: #245c4c;
    --green-everpass-90: #194034;

    --grey-10: #f5f5f5;
    --grey-20: #e9e9e9;
    --grey-30: #D9D9D9;
    --grey-40: #C4C4C4;
    --grey-50: #9d9d9d;
    --grey-60: #bababa;
    --grey-70: #555555;
    --grey-80: #434343;
    --grey-90: #212121;

    --red: #de425b;
    --alert-red: #F44336;
    --dark-red: #c2344a;
    --darker-red: #B71C1C;
    --darkest-red: #5E0C0C;
    --light-red: #FEEBEB;

    --primary-color: var(--black);
    --secondary-color: var(--green-everpass-40);

    --hover-color: var(--grey-20);

    --font-color: var(--black);
    --font-secondary-color: var(--black);

    --active-font-color: var(--grey-50);

    //override
    --dark-slate-blue: var(--primary-color);
    --dark-sky-blue: var(--primary-color);
    --dark-slate-blue-60: var(--grey-50);
    --anchor-text-color: var(--green-everpass-40);

    //APPROVALS

    .spotlight-preview-approval .SpotlightPlayerContainer .SpotlightPlayerControls {
        background-color: var(--grey-10);

        .control_button {
            border-color: var(--black);
            background-color: var(--black);

            >svg {
                fill: var(--white);
            }
        }

        .control_progressbar .progress {
            background-color: var(--green-everpass-40);
        }
    }

    .Organizations {

        .wrapper-approvals {
            width: 100%;

            .icon-wrapper {
                background-color: var(--grey-10);
                width: 80px;
                height: 80px;
                border-radius: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                .icon {
                    color: var(--black);
                    width: 40px;
                    height: 40px;
                }
            }

        }

    }

    .Tag.green {
        color: #757912;
        background-color: #EAEDA0;
        border: none;
        width: fit-content;
        font-family: "Roobert SemiBold", sans-serif;
        font-weight: 650;
    }

    .Tag.pending_review {
        color: #CC660F;
        background-color: #F7E6D9;
        border: none;
        width: fit-content;
        font-family: "Roobert SemiBold", sans-serif;
        font-weight: 650;
    }


    .Tag.approved {
        color: #00878F;
        background-color: #CFEAEF;
        border: none;
        width: fit-content;
        font-family: "Roobert SemiBold", sans-serif;
        font-weight: 650;
    }

    .Tag.declined {
        color: #B83B41;
        background-color: #F4E0E1;
        border: none;
        width: fit-content;
        font-family: "Roobert SemiBold", sans-serif;
        font-weight: 650;
    }

    .Tag.declined {
        color: #B83B41;
        background-color: #F4E0E1;
        border: none;
        width: fit-content;
        font-family: "Roobert SemiBold", sans-serif;
        font-weight: 650;
    }

    .Tag.draft {
        color: #E075DC;
        background-color: #FAE9F9;
        border: none;
        width: fit-content;
        font-family: "Roobert SemiBold", sans-serif;
        font-weight: 650;
    }

    .Tag.light-border {
        color: #5A6FCC;
        background-color: #E5E8F7;
        border: none;
        width: fit-content;
        font-family: "Roobert SemiBold", sans-serif;
        font-weight: 650;
    }

    .spotlight-preview-approval {

        .spotlight-preview-header {
            button {
                border: none;
                padding: 10px 12px;
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 650;
            }

            button:first-of-type {
                color: var(--red);
                background-color: var(--light-red);
            }

            button:nth-of-type(2) {
                color: var(--white);
                background-color: var(--black);
            }
        }

        .redirect-link {
            border: 2px solid var(--black);
            color: var(--black);
            padding: 10px 12px;
            border-radius: 6px;
            display: flex;
            flex-direction: row-reverse;
            font-size: 16px;

            .icon {
                width: 17px;
                height: 17px;
                padding-left: 4px;
            }
        }
    }

    .Organization {

        .screenshare-widget,
        .ate-widget,
        .campaign-value-widget {
            box-shadow: none;
            height: 200px !important;
            border: 1px solid var(--grey-20);
            border-radius: 1px;

            .MuiTypography-root:first-child {
                font-size: 18px;
                font-family: 'Roobert SemiBold', sans-serif;
            }

            .MuiBox-root:nth-child(2) {
                font-size: 18px;
                min-width: 270px;
                font-family: 'Roobert SemiBold', sans-serif;

                .MuiTypography-root:nth-child(1) {
                    font-size: 24px;
                    font-family: 'Roobert Regular', sans-serif;
                }

                .MuiTypography-root:nth-child(3) {
                    margin-left: 10px;
                    font-size: 16px;
                    font-family: 'Roobert Regular', sans-serif;
                }

            }
        }

        .empty-widget {

            p,
            a {
                font-family: 'Roobert SemiBold', sans-serif;
            }
        }

        .widgets-container {
            height: 200px;
            margin: 16px 0px;

            .MuiGrid-root .top-value-locations-widget {

                .MuiBox-root {
                    span {
                        font-size: 18px;
                        font-family: 'Roobert SemiBold', sans-serif;

                        p {
                            font-size: 18px;
                            font-family: 'Roobert SemiBold', sans-serif;
                        }
                    }
                }

                ol li .MuiBox-root p {
                    font-size: 12px !important;
                    font-family: 'Roobert Regular', sans-serif;

                }


            }

            .MuiGrid-item {
                padding: 0px;
                box-shadow: none;
                width: 411px;
                height: 200px;

                .empty-state-icon-container {
                    height: 48px;
                    width: 48px;
                    border-radius: 50%;
                    background-color: var(--grey-10);

                    img {
                        margin: 13px;
                        fill: black;
                        height: 24px;
                        width: 24px;
                    }
                }

                .empty-widget,
                .top-value-locations-widget {
                    padding: 16px auto;
                    border-radius: 1px;
                    height: 200px;
                    box-shadow: none;
                    border: solid 1px var(--grey-20);
                    font-size: 12px;
                    font-family: 'Roobert SemiBold', sans-serif;

                }
            }
        }

        //INSIGHT

        .insights-campaign-details {
            width: 100%;

            .section-title {
                font-family: 'Roobert SemiBold', sans-serif;
            }
            .title-box {
                align-items: center;
                font-family: 'Roobert Regular', sans-serif;
                >div .campaign-name {
                    font-family: 'Roobert SemiBold', sans-serif;
                }
            }

            button {
                font-family: 'Roobert SemiBold', sans-serif;
                font-size: 14px;
                border-radius: 6px;
                border: solid black 2px;
                min-width: 169px;
                height: 48px;
                color: var(--black);

                &:disabled {
                    border-color: var(--grey-20) !important;
                    color: var(--grey-30);
                }

                &:hover {
                    background-color: var(--grey-30);
                }
            }
        }

        .campaign-spotlight-actions {
            .campaign-search-input {
                background-color: var(--grey-10);
                border-color: var(--grey-10);
                width: 75%;
                color: var(---black);
                display: flex;
                flex-direction: row-reverse;

                input {
                    margin-left: 1rem;
                }
            }

            .download-csv {
                border-radius: 6px;
                border: solid black 2px;
                min-width: 169px;
                height: 48px;
                color: var(--black);

                &:disabled {
                    border-color: var(--grey-20) !important;
                    color: var(--grey-30);
                }

                &:hover {
                    background-color: var(--grey-30);
                }
            }
        }

        .grade-box {
            padding: 10px 12px;

            .grade-info {
                p:nth-child(1) {
                    font-size: 12px;
                }

                p:nth-child(2) {
                    font-size: 11px;
                }
            }

            a,
            a:visited {
                font-size: 12px;
                font-family: "Roobert Bold", sans-serif;
                font-weight: bold;
                color: var(--black);
                text-decoration: none;
            }
        }

        .DayPicker-Months {
            border-radius: 24px;
        }

        .insight-header {
            align-items: center;
            margin: 0px;
            padding-left: 32px;
            border: 1px solid var(--green-everpass-10);
            height: 96px !important;

            button[data-layout=badgeBlank]::before {
                position: static;
            }

            .MuiBox-root {
                right: 32px;
            }

            .MuiButtonBase-root {
                border: solid 2px var(--grey-20) !important;
                border-radius: 6px !important;
                padding: 12px !important;

            }

        }

        .MuiTabs-root {
            padding-left: 0px;
            padding-top: 18px;

        }

        .search-download-container {
            margin-top: 16px;

            .download-csv {
                border-radius: 6px;
                border: solid black 2px;
                min-width: 169px;
                height: 48px;
                color: var(--black);

                &:disabled {
                    border-color: var(--grey-20) !important;
                    color: var(--grey-30);
                }

                &:hover {
                    background-color: var(--grey-30);
                }
            }

            .MuiInputBase-root {
                background-color: var(--grey-10);
                border-color: var(--grey-10);
                width: 100%;
                color: var(---black);
                display: flex;
                flex-direction: row-reverse;

                input {
                    margin-left: 1rem;
                }
            }


        }

    }

    .CollectionEditor {
        .editor-container {
            .AssetsList .assets .AssetItem {
                box-shadow: none;
                border: solid 1px var(--grey-30);

                .footer {
                    font-family: 'Roobert Regular', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19.2px;
                    letter-spacing: 0%;
                    color: var(--grey-50)
                }

                .thumbnail-container {
                    .thumbnail {
                        .icon.more {
                            color: var(--white);
                            right: 8px;
                            top: 8px;
                            background-color: #F5F5F54D;
                        }

                        .icon.assets-label-btn {
                            color: var(--white);
                            top: 8px;
                            left: 8px;
                            background-color: #F5F5F54D;
                        }
                    }

                    .thumbnail-button-container {
                        z-index: 9999;
                        position: relative;

                        .icon.more {
                            color: var(--white);
                            right: 8px;
                            top: 8px;
                            position: absolute;
                            background-color: #F5F5F54D;
                        }

                        .icon.assets-label-btn {
                            color: var(--white);
                            top: 8px;
                            left: 8px;
                            position: absolute;
                            background-color: #F5F5F54D;
                        }
                    }
                }

            }

            .tag-list .tag-item {
                background-color: var(--green-everpass-40);
            }

            .editor-header {
                align-content: center;
                height: 108px;
                background: var(--white-two);

                .title {
                    color: var(--black);
                    margin-left: 32px;
                    font-size: 24px;
                }

                .header-back-url {
                    font-family: "Roobert Regular", sans-serif;
                    font-weight: 650;
                    cursor: pointer;
                    margin-left: 32px;
                    position: relative;
                    color: var(--green-everpass-40);
                    font-size: 16px;
                    display: flex;
                    flex-direction: row;
                    right: 10px;
                }
            }

            .wrapper {
                .title-container {
                    flex-direction: row;
                    padding: 20px 24px 20px 32px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .title-control .helper-text {
                        margin-left: 0px;

                    }

                    .title-placeholder {
                        display: flex;
                        flex-direction: column;

                        .asterisk {
                            position: absolute;
                            bottom: 45px;
                            left: 125px;
                            font-size: 28px;
                            color: var(--red-messages);
                            cursor: default;
                            padding-right: 2px;
                        }
                    }

                    .MuiFormControl-root {
                        width: 845px;
                    }

                    .action-buttons {
                        display: flex;
                        flex-direction: row;
                        height: 100%;
                        align-items: center;

                        .button {
                            color: var(--green-everpass-40);
                            border: none;
                            width: 80px;
                            height: 50px;
                            margin: 0 8px;
                            padding: 0 25px;
                            border-radius: 6px;
                            font-family: 'Cabin', sans-serif;
                            font-size: 14px;
                            text-transform: none;
                            box-shadow: none;
                            font-weight: 600;

                            &.ok {
                                background-color: var(--green-everpass-10);
                                color: var(--green-everpass-40);
                                width: 162px;
                            }

                            &.disabled {
                                background-color: var(--green-everpass-10);
                                cursor: default;
                                opacity: .45;
                                color: var(--green-everpass-20);
                            }
                        }
                    }
                }
            }
        }
    }

    .CollectionContents .contents-container {
        .contents-header .icon-button.new:hover {
            background-color: var(--green-everpass-40);
            border: none;
        }

        .tag-list {
            .tag-item {
                background-color: var(--green-everpass-40);

                button {
                    border: none;
                }
            }
        }

        .contents-content {
            .AssetsList .assets .AssetItem {
                box-shadow: none;
                border: solid 1px var(--grey-30);

                .footer {
                    font-family: 'Roobert Regular', sans-serif;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19.2px;
                    letter-spacing: 0%;
                    color: var(--grey-50)
                }

                .thumbnail-container {
                    .thumbnail {
                        .icon.more {
                            color: var(--white);
                            right: 8px;
                            top: 8px;
                            background-color: #F5F5F54D;
                        }

                        .icon.assets-label-btn {
                            color: var(--white);
                            top: 8px;
                            left: 8px;
                            background-color: #F5F5F54D;
                        }
                    }

                    .thumbnail-button-container {
                        z-index: 9999;
                        position: relative;

                        .icon.more {
                            color: var(--white);
                            right: 8px;
                            top: 8px;
                            position: absolute;
                            background-color: #F5F5F54D;
                        }

                        .icon.assets-label-btn {
                            color: var(--white);
                            top: 8px;
                            left: 8px;
                            position: absolute;
                            background-color: #F5F5F54D;
                        }
                    }
                }

            }
        }
    }


    .CollectionLibrary {
        justify-content: center;
        display: flex;
        max-width: 100%;
        margin-left: 308px;

        .EmptyList {

            .image-container {
                width: 48px;
                height: 48px;
                border-radius: 50px;
                background-color: var(--grey-20);
                align-items: center;
                box-sizing: border-box;
                display: inline-block;
                margin-bottom: 20px;

                img {
                    margin: 13px;
                    fill: black;
                    height: 24px;
                    width: 24px;
                }

            }

            .actions {

                .button {
                    border-radius: 8px;
                    width: 136px;
                    height: 51px;
                    gap: 16px;
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19.2px;
                }

            }

            margin-top: 125px;

            .title {
                height: 28px;
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 650;
                font-size: 23px;
                line-height: 27.6px;
                letter-spacing: 0%;
                text-align: center;

            }

            .description {
                font-family: "Roobert Regular", sans-serif;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.1px;
                text-align: center;
                width: 616px;
                font-size: 14px;
                color: var(--black);
                max-width: 380px;
            }

        }
    }

    .collection-library {
        width: 100%;

        .collection-library-header {
            display: flex;
            height: 117px;
            align-items: center;
            justify-content: start !important;

            .header-back-url {
                font-family: "Roobert Regular", sans-serif;
                font-weight: 650;
                cursor: pointer;
                margin-bottom: 50px;
                position: relative;
                color: var(--green-everpass-40);
                font-size: 16px;
                display: flex;
                flex-direction: row;
                right: 10px;
            }

            .header-title {
                position: absolute;
                display: flex;
                flex-direction: row;
                left: 20px;
                margin-top: 50px;
                height: 38px;
                width: 350px;
                align-items: center;

                .title {
                    color: var(--black);
                    margin-right: 10px;
                    font-size: 32px;
                }

                .subtitle {
                    color: var(--grey-50);
                    margin-left: 5px;
                    padding-left: 10px;
                    border-left: 1px solid var(--grey-30);
                    font-size: 16px;
                }
            }

            .collection-library-sidebar {
                width: 308px;
                top: 118px;
                left: 0px;
                bottom: 0px;
                position: absolute;
                border-right: 1px solid #00000020;

                .EmptyList {
                    height: 120px;
                    margin-top: 30px;

                    .title {
                        margin-bottom: 10px !important;

                    }

                    .description {
                        color: var(---black);
                        font-size: 12px;
                        font-family: "Roobert Regular", sans-serif;
                        line-height: 15.17px;
                        letter-spacing: 0.1px;
                        text-align: center;
                        width: 245px !important;

                    }

                    .image-container {
                        width: 48px;
                        height: 48px;
                        border-radius: 50px;
                        background-color: var(--grey-20);
                        align-items: center;
                        box-sizing: border-box;
                        display: inline-block;
                        margin-bottom: 20px;

                        img {
                            margin: 13px;
                            fill: black;
                            height: 24px;
                            width: 24px;
                        }

                    }
                }

                .search-box {
                    height: 48px;
                    width: 276px;
                    background-color: var(--grey-10);
                    border: none;
                    left: 16px
                }
            }
        }

    }

    .audio .right-content {
        background-color: var(--white);
    }

    .CreateSpotlight {

        .create-spotlight-title-description-container {
            gap: 7px;
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;

            .create-spotlight-title {
                font-weight: 650;
                font-size: 24px;
                font-family: "Roobert SemiBold", sans-serif;

                button {
                    position: relative;
                    right: 85px !important;

                }
            }

            .create-spotlight-description {
                color: var(--grey-70);
                margin: 0 auto;
                width: 467px;
                font-family: "Roobert Regular", sans-serif;
                font-size: 16px;
                line-height: 19.2px;
                text-align: center;
            }
        }

        .create-spotlight-grid {
            width: 800px;
            gap: 10px;

            .create-spotlight-box-card-text-container {
                margin: 0 15px;
                margin-bottom: 25px;
                align-items: flex-start;
                height: 187px;
                width: 212px;

                .create-spotlight-box-container {
                    &:hover {
                        .box-icon {
                            display: none;
                        }
                    }

                    .create-spotlight-box {
                        background-color: var(--grey-20) !important;
                        height: 116px;

                        &:hover {
                            background-color: var(--grey-60) !important;

                            .spotlight-box-orientation-picker {
                                background-color: var(--grey-60) !important;
                            }
                        }

                        .spotlight-box-orientation-picker {
                            background-color: var(--grey-20) !important;
                            height: max-content;

                            .box-selected {
                                background-color: var(--grey-60) !important;

                            }

                            .options {
                                color: var(--black);
                                height: 40px;
                                border: none !important;
                                background-color: var(--green-everpass-10) !important;

                                .options-title,
                                .options-spec {
                                    border-radius: 4.18px;
                                    color: var(--black);
                                    font-family: "Roobert Regular", sans-serif !important;
                                }

                                &:hover {
                                    background-color: var(--green-everpass-20) !important;
                                }
                            }

                        }

                        .box-title {
                            display: none;
                        }

                        .box-icon {
                            background-color: var(--grey-20) !important;
                        }
                    }
                }

                .title-tooltip-container {
                    margin-left: 10px;
                    width: 212px;
                    text-align: left !important;
                    display: flex !important;
                    flex-direction: column;

                    .title {
                        font-weight: 500;
                        font-size: 16px;
                        margin-bottom: 5px;
                    }

                    .tooltip {
                        line-height: 120% !important;
                        width: 197px;
                        color: var(--grey-50);
                        font-size: 12px;
                    }
                }
            }


        }
    }

    * {
        font-family: 'Roobert Regular', sans-serif;
        font-weight: 200;
    }

    .CreateDefaultSchedule .footer .button {
        border-radius: 8px;
        height: 42px;
        font-family: 'Roobert Regular', sans-serif;

        &.disabled-button {
            background-color: var(--grey-30);
            color: white;
        }
    }

    .MixPanel .default-section .default-label {
        background-color: #C2FFEA;
        color: #007A50;
    }

    .SmartPlaylistSlider {
        margin-top: 17px;
        width: 400px;
        position: absolute;
        top: 2px;
        right: 34px;
    }

    .Mixes .mixes-container .info-container .mixes-calendar-container .header {
        margin: 16px 0 16px 35px;
        margin-bottom: 4px;

        >span {
            font-family: "Roobert SemiBold", sans-serif;
        }

    }


    .MixPanel .paper-shadow {
        justify-content: center;
    }

    .MixPanel .general-info-container .action-buttons .divider {
        margin-top: 0;
    }

    .YourCurrentMix .slider_container.panel-open {
        margin-right: 10px;
    }

    .Editor .Title .action-buttons .circle-button {
        border: none;
        background-color: #F5F5F5;

        &:hover {
            background-color: #D9D9D9;
        }
    }

    .CreateSchedule .footer .button {
        border-radius: 8px;
        font-family: "Roobert Regular", sans-serif;
    }

    .CreateSchedule .switch-composition .active-oval-side {
        background-color: var(--green-everpass-40);
    }

    .script_info {
        .back-box {
            bottom: 30px;
        }

        .Tag {
            margin: 6px 0 0 0;
            background-color: #C2FFEA;
            color: #007A50;
        }
    }

    .MixPanel .panel-footer .button {
        font-family: "Roobert Regular", sans-serif;
        border-radius: 8px;
        padding: 0 14px;
        height: 38px;
    }

    .SequenceHandler .sequence-chunk .chunk-dropdown-container .dropdown-title,
    .Title .title-container .title-control .title-input .input {
        font-family: "Roobert Regular", sans-serif;
    }

    .Title .action-buttons .button {
        min-width: 109px;
        padding: 0 0px;
        border-radius: 8px;
        font-family: "Roobert Regular", sans-serif;

        &.cancel-button {
            color: var(--green-everpass-70);

            &:hover {
                text-decoration: underline;
                background-color: white;
            }
        }
    }

    .StateSelector .state-selector-content-container .state-selector-content .state-selector-content-category .create-chunk-box-container .create-chunk-box .box-title {
        font-family: "Roobert Regular", sans-serif;
    }

    .ScheduleListItem {
        .border.local {
            background: linear-gradient(180deg, var(--green-everpass-40) 0%, var(--green-everpass-70) 100%);
        }

        .display-rules-toggle {
            border: 2px solid var(--green-everpass-70);
            color: var(--green-everpass-70);
        }

        &:hover {
            background-color: #F5F5F5;
            border: 1px solid #C4C4C4 !important;

            .grid .days {
                color: var(--green-everpass-70) !important;
            }
        }
    }

    .calendar-container .mixes-calendar .mixes-calendar-column .column-content .mix.focused {
        border-color: var(--green-everpass-40);

        .mix-title {
            color: var(--green-everpass-40);
        }
    }

    .YourCurrentMix {
        min-height: 218px;
        background: #F5F5F5;


        .script_info .subtitle {
            text-transform: capitalize;
            font-family: "Roobert Regular", sans-serif;
        }

        .subtitle.small {
            font-family: "Roobert SemiBold", sans-serif;
        }

        .chart_container .subtitle.small {
            padding: 0 20px 0 10px;
            color: var(---black);
            text-transform: capitalize;
            font-size: 14px;
        }

        .Slider .next,
        .Slider .prev {
            top: 78px
        }

        .script_info {
            padding: 45px 28px 32px;
        }

        .chart_container {
            height: 160px;
            background-color: white;
            border-radius: 10px;
            border: 1px solid #E9E9E9;
            padding: 11px;
            position: relative;
            top: 15px;
            margin-left: 23px;
            margin-right: 16px;

            .chart {
                margin-top: 0;
            }
        }

        .slider_container {
            background-color: white;
            height: 160px;
            padding: 23px 0 0 0;
            top: 14px;
            position: relative;
            border-radius: 11px;
            border: 1px solid #E9E9E9;
        }

        .subtitle,
        .script_name {
            font-family: "Roobert SemiBold", sans-serif;
        }
    }

    .MixEntryTile .script_state_name_container .script_state_name {
        font-family: "Roobert Bold", sans-serif;
    }

    ._pendo-step-container-styles {
        background-color: var(--green-everpass-70) !important;
    }

    button[data-layout="badgeBlank"] img {
        display: none !important;
    }

    button[data-layout="badgeBlank"]::before {
        content: '';
        display: block;
        width: 26px;
        height: 26px;
        background-image: url('./assets/brain-pendo.svg');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        left: 88px;
        top: 3px;
        position: relative;
    }

    .Settings {
        background-color: white;
        padding: 0 80px;

        .content {
            margin-top: 0;
            padding: 0;
            background-color: white;
        }

        .ToolBar {
            padding: 0;

            .container {
                padding: 0;
    
                .title {
                    margin: 0;
                    padding: 40px 0;
                    font-size: 32px;
                    font-weight: 650;
                }
            }
        }

        .paper-shadow {
            padding: 0;
            margin: 0;
        }
        
        .tabs-container {
            background: white;

            .MessageBar {
                padding-top: 24px ;
                width: auto;
                height: unset;
                border-radius: 6px;
                margin: 0;
    
                .container {
                    background-color: #E3F3FF;
                    border-radius: 6px;
                    padding: 10px 0px;
    
                    .icon {
                        margin-left: 16px;
                    }
                }
    
                .label,
                .icon,
                .closeColor {
                    color: #063071
                }
                b {
                    font-family: "Roobert Bold", sans-serif;
                }
            }

            .TitleBar {
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 24px;

                span {
                    font-weight: 500;
                    font-size: 20px;
                }

                p {
                    color: var(--grey-70);
                    font-weight: 400;
                    font-size: 16px;
                }

                .description {
                    padding-right: 24px;
                }

                .button {
                    margin: unset;
                    font-family: 'Roobert SemiBold', sans-serif;
                    height: 51px;
                    min-width: 140px;
                    font-size: 16px;
                    font-weight: 650;
                    border-radius: 8px;
                    padding: 16px;
                    background-color: var(--black);
                    border: 2px solid var(--black);
                    color: white;

                    &:hover {
                        background-color: var(--grey-80);
                        border: 2px solid var(--grey-80);
                        color: var(--grey-10);
                    }

                    &:focus {
                        background-color: var(--grey-80);
                        border: 2px solid var(--black);
                        color: var(--grey-10);
                    }

                    &:active {
                        border: 2px solid var(--black);
                        background-color: var(--grey-80);
                        color: var(--grey-10);
                    }
                }
            }

            .box-container {
                border: 1px solid var(--grey-30);
                margin: 0px;
                width: 100%;
                border-radius: 6px;

                .container {
                    padding: 0;
                }

                .content {
                    padding: 0;
                }

                .section {
                    border-bottom: unset;
                    border-bottom: 1px solid var(--grey-30);
                    padding: 32px;

                    &:last-child {
                        border-bottom: none;
                    }

                    .subtitle {
                        font-size: 18px;
                        font-weight: 650;
                        margin: 0;
                        padding: 0;
                        margin-bottom: 16px;
                    }

                    .org-id {
                        color: var(--grey-70);
                        font-size: 18px;
                        font-weight: 400;
                        margin-bottom: 0;
                    }

                    .desc-sub-title {
                        color: var(--grey-70);
                        font-size: 18px;
                        font-weight: 400;
                        margin-bottom: 24px;
                    }

                    .LogoAssetUploader {
                        margin-left: 0;
                    }

                    .pickers {
                        .color-selector, .label, .select-control {
                            margin: 0 !important;
                            margin-bottom: 8px !important;
                        }
                    }
                    .pickers:last-child {
                        margin-bottom: 0 !important;
                    }
                }
            }
        }

        .Brandkit, .General, .user-management, .org-management, .organization-labels {
            background: white;
            padding: 0;

            .section &.container {
                padding-right: 32px;
            }
        }

        .specific-dates, .weekly-section {
            .everpass-styled-button {
                margin: unset;
                font-family: 'Roobert SemiBold', sans-serif;
                height: 51px;
                min-width: 140px;
                font-size: 16px;
                font-weight: 650;
                padding: 16px;
                background-color: var(--black);
                border: 2px solid var(--black);
                color: white;
                box-shadow: none;

                &:hover {
                    background-color: var(--grey-80);
                    border: 2px solid var(--grey-80);
                    color: var(--grey-10);
                }

                &:focus {
                    background-color: var(--grey-80);
                    border: 2px solid var(--black);
                    color: var(--grey-10);
                }

                &:active {
                    border: 2px solid var(--black);
                    background-color: var(--grey-80);
                    color: var(--grey-10);
                }
            }

            .day-name {
                font-family: 'Roobert SemiBold', sans-serif;
                font-size: 16px;
                font-weight: 650;
                margin-bottom: 24px;
            }

            .nopadleft {
                padding-left: 0;
            }
        }

    }

    .LogoAssetUploader {
        .logo-preview {
            border: 1px dashed var(--grey-30);
            border-radius: 6px;
        }

        .buttons {
            margin-top: 24px;
            
            .button {
                background-color: unset;
                width: 80px;
                margin: 0;
                padding: 0;

                &.button-up{
                    min-width: unset;
                }

                &.containedPrimary {
                    color: var(--green-everpass-40);
                    border: none;
                    height: unset;
                    border-radius: 6px;
                    font-size: 16px;
                    text-transform: none;
                    box-shadow: none;
                    font-weight: 650;
                    font-family: 'Roobert Regular', sans-serif;
                }
               
    
                &.ok {
                    background-color: var(--green-everpass-10);
                    color: var(--green-everpass-40);
                    width: 162px;
                }
    
                &.disabled {
                    background-color: var(--green-everpass-10);
                    cursor: default;
                    opacity: .45;
                    color: var(--green-everpass-20);
                }
            }
            
        }
    }

    tr {
        background-color: var(--grey-10);
        color: var(--grey-90);

        th {
            .th-content {
                font-family: "Roobert Bold", sans-serif !important;
            }
        }

        td {
            font-size: 14px;

            .thumbnail {
                border-radius: 6px;
            }
        }
    }


    .calendar-container {
        height: calc(100vh - 411px);
        padding-bottom: 0px;
    }

    .calendar-container .mixes-calendar .mixes-calendar-column .column-content .mix {
        background-color: var(--green-everpass-10);
    }

    .calendar-container .selector-and-indicators .TimezonePicker {
        align-content: flex-start;

        .text {
            color: var(--green-everpass-40);
            font-size: 12px;
            font-family: 'Roobert Regular', sans-serif;
        }
    }

    .Mixes .mixes-container .info-container .mixes-calendar-container .header .date-label {
        font-size: 15px;
        color: #555555;
    }



    .PendoBadge .pendo-message {
        color: #555555;
    }

    .LiveEventPlaying {
        background: #F5F5F5;

        .event-container {
            height: 152px;
            margin: 16px;
            border: 1px solid #E9E9E9;
            border-radius: 16px;
            padding: 16px;
            position: relative;

            .thumbnail {
                width: 168px;
                height: 88px;
                position: absolute;
                border-radius: 10px;
                left: 32px;
            }

            .event-data {
                padding-left: 198px;

                .title {
                    margin-top: 4px;
                    color: #C4C4C4;
                }

                .event-date {
                    font-family: "Roobert SemiBold", sans-serif;
                    font-size: 14px;
                }

                .event-name {
                    font-family: "Roobert Regular", sans-serif;
                    text-transform: uppercase;
                    font-weight: 600;
                }

                .event-message {
                    background-color: #E3F2FD;
                    color: #063071;
                    position: relative;
                    right: 184px;
                    width: calc(100% + 148px);
                }
            }
        }
    }

    .Dialog .title-text {
        font-weight: 650;
        font-family: "Roobert SemiBold", sans-serif;
        margin-top: -20px;
        margin-bottom: -16px;
    }

    .MassContent {
        margin-top: 0;
        display: flex;
        flex-direction: row;
        min-height: 74px;

        .test-button {
            border: none;
            border-radius: 6px;
            font-family: "Roobert SemiBold", sans-serif;
            display: flex;
            justify-content: center;
            height: 39px;
            padding: 8px 12px;
            font-size: 14px;
            color: var(--white);
            background-color: var(--black);

            &.download {
                border: none;
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 650;
                height: 48px;
                display: flex;
                justify-content: center;
                font-size: 14px;
                color: var(--green-everpass-90);
                background-color: var(--green-everpass-10);
                padding: 16px 14px;
            }
        }


        .devices-progress-wrapp {
            width: 50%;

            .progress-bar {
                margin-top: 10px;
            }

            .devices-selected {
                font-size: 16px;
                font-weight: 650;
                line-height: 19.2px;
                font-family: "Roobert SemiBold", sans-serif;
                margin-bottom: 10px;
            }
        }
    }

    .Footer {
        .devices {

            .online-devices {
                display: flex;
                flex-direction: row-reverse;
                gap: 4px;
                color: var(--grey-80);

                .number {
                    color: var(--white) !important;
                    background-color: #57C7A0;
                    width: 16px;
                    height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .offline-devices {
                display: flex;
                flex-direction: row-reverse;
                gap: 4px;
                color: var(--grey-80);

                .number {
                    color: var(--white);
                    background-color: var(--dark-red);
                    width: 16px;
                    height: 16px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }
        }

        .disclaimer {
            font-size: 13px;
            font-weight: 400;
            line-height: 15.6px;
        }
    }

    .BandwidthTest {
        margin-bottom: 32px;

        .test-confirmation {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            height: 48px;
            margin-top: 16px;
            margin-bottom: 16px;
            box-sizing: border-box;
            padding: 12px 15px;
            border-radius: 6px;
            background-color: var(--grey-10);

            .checkbox-label {
                font-size: 14px;
                line-height: 16px;
            }
        }

        .test-description {
            font-size: 18px;
            font-weight: 400;
            line-height: 21.6px;
        }

    }

    ._pendo-resource-center-badge-container img {
        display: none !important;
    }

    ._pendo-resource-center-badge-container::before {
        content: '';
        display: block;
        width: 56px;
        height: 56px;
        background-image: url('./assets/pendo.svg');
        background-size: cover;
        background-repeat: no-repeat;
    }

    .calendar-container .mixes-days .mixes-calendar-column-header.today {

        .day,
        .date {
            color: var(--green-everpass-40)
        }
    }

    .rename-device-menu {
        border: 1px solid var(--grey-50);
        border-radius: 8px;

        .actions {
            display: flex;
            justify-content: flex-end;

            .btn-cancel-rename {
                display: none;
            }

            .btn-rename-device {
                border: none;
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                display: flex;
                justify-content: center;
                font-size: 14px;
                color: var(--white);
                background-color: var(--black);
            }

            .btn-rename-device:disabled {
                opacity: 0.3;
            }
        }

        .body .label {
            font-family: "Roobert SemiBold", sans-serif;
            font-weight: 750;
            color: var(--grey-50);
        }

        .header {
            background-color: var(--grey-10);
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .title {
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 750;
            }
        }
    }

    .material-time-picker>div::after {

        border-bottom-color: var(--secondary-color);
    }

    .ColorPicker .color-selector .form-field .input .icon-create {
        color: var(--green-everpass-70);
    }

    .promotions-manager .loading-canva-button {
        background: rgb(99 156 93 / 30%);
    }

    .zip-code-container .edit-zip-code:hover {
        background-color: var(--green-everpass-10);
    }

    .add-user-modal .modal-container .disabled {
        background-color: var(--grey-30);
        border-color: var(--grey-30);
        color: white;
    }

    // SURVEY
    .survey-input-label {
        font-weight: 750;
        font-size: 14px;
        font-family: "Roobert Bold", sans-serif;
    }

    .add-question-button {
        color: var(--green-everpass-90);
        height: 40px;
        width: 189px;
        padding: unset !important;
        background-color: var(--green-everpass-10);
        border: 2px solid var(--green-everpass-10);
        border-radius: 6px;
        font-family: "Roobert", sans-serif;
        font-size: 16px;
        font-weight: 650;

        &:hover {
            background-color: var(--green-everpass-20);
            border: 2px solid var(--green-everpass-20);
        }

        &:focus {
            background-color: var(--green-everpass-40);
            border: 2px solid var(--green-everpass-10);
            color: var(--green-everpass-10);
        }

        &:active {
            background-color: var(--green-everpass-40);
            border: 2px solid var(--green-everpass-90);
        }
    }

    .surveys,
    .survey-details {
        .content {
            padding: unset;

            .box-container {
                .section {
                    height: 87px;
                    border-bottom: solid 1px var(--grey-30);
                    padding-bottom: unset;

                    .title {
                        padding-left: 40px;
                        font-size: 32px;
                        font-weight: 650;
                    }

                    .create-survey {
                        padding-right: 40px;

                        .create-button {
                            font-family: 'Roobert SemiBold', sans-serif;
                            height: 39px;
                            font-size: 16px;
                            font-weight: 650;
                            border-radius: 8px;
                            padding: 8px 12px;
                            background-color: var(--black);
                            border: 2px solid var(--black);
                            color: white;

                            &:hover {
                                background-color: var(--grey-80);
                                border: 2px solid var(--grey-80);
                                color: var(--grey-10);
                            }

                            &:focus {
                                background-color: var(--grey-80);
                                border: 2px solid var(--black);
                                color: var(--grey-10);
                            }

                            &:active {
                                border: 2px solid var(--black);
                                background-color: var(--grey-80);
                                color: var(--grey-10);
                            }
                        }
                    }
                }

                .container {
                    margin: 24px 40px;

                    .search-box {
                        background-color: var(--grey-10);
                        border-radius: 100px;
                        border: none;
                        display: flex;
                        width: 100%;
                        height: 48px;
                        flex-direction: row-reverse;

                        .input {
                            font-family: "Roobert Regular", sans-serif;
                            font-weight: 400;
                            font-size: 14px;
                            color: var(--grey-80);
                        }

                        .css-1laqsz7-MuiInputAdornment-root {
                            margin-left: 0;
                            margin-right: 8px;
                            color: var(--grey-90);
                        }

                        .MuiFormControl-root {
                            box-sizing: border-box;
                            margin-top: 0;
                        }

                        &:hover {
                            background-color: var(--grey-20);
                        }

                        .icon-color {
                            color: var(--grey-90);
                        }
                    }

                    table {
                        tbody tr {
                            height: 56px;
                        }

                        th {
                            height: 58px;
                        }
                    }
                }

                .search-box:has(input:focus) {
                    background-color: var(--white);
                    border: 1px solid var(--grey-70)
                }

            }
        }
    }

    //LIBRARY
    .AssetUploader {
        width: 100%;



        .assetuploader-header {
            height: 90px;
            border-width: 1px;
            padding-top: 24px;
            padding-left: 40px;
            gap: 8px;

            .header-back-url {
                font-family: "Roobert Regular", sans-serif;
                font-weight: 650;
                cursor: pointer;
                margin-bottom: 20px;
                position: relative;
                color: var(--green-everpass-40);
                font-size: 16px;
                display: flex;
                flex-direction: row;
                right: 10px;
            }

            .title {
                position: relative;
                font-family: 'Roobert SemiBold', sans-serif;
                font-weight: 650;
                font-size: 24px;
                line-height: 28.8px;
                letter-spacing: 0%;
                width: 193px;
                height: 29px;
            }

        }

        .uploader-view-body {
            border: none !important;
            background-color: white !important;

            .asset-list {
                width: 100%;
                background-color: var(--white);
                border-top: solid 1px var(--grey-20);
                padding-top: 0px;

                .SpotlightAssetUploader {
                    width: fit-content;
                    max-width: 616px;
                    max-height: 228px;

            background-color: white;

                    .asset-uploader-container {
                        border: dotted solid 2px;
                        border-color: var(--grey-30);
                        max-width: 616px;
                        height: 228px;
                        color: var(--black);
                        position: relative;

                        .uploader-message {
                            width: 568px;
                            font-size: 13px;
                        }

                        &:hover {
                            border: 2px var(--green-everpass-40) dashed;
                        }

                        .uploader-main {

                            .uploader-title {
                                font-size: 25px;

                            }

                            .img-container {
                                width: 80px;
                                height: 80px;
                                border-radius: 50px;
                                background-color: white;
                                align-items: center;
                                box-sizing: border-box;
                                display: inline-block;
                                margin-bottom: 20px;

                                img {
                                    margin: 16px;
                                    fill: black;
                                    height: 40px;
                                    width: 40px;
                                }

                            }

                        }

                    }
                }

                .asset-cart-scroll-target {
                    width: 616px;
                    margin-bottom: 75px;
                    margin-top: 20px;

                    +.asset-cart-scroll-target {
                        margin-top: 0px;
                        margin-bottom: 10px;

                        .asset-card-container {
                            height: auto;

                            .asset-card {
                                min-height: 116px;
                            }
                        }

                    }

                    &.disabled {
                        pointer-events: none;
                    }
                }

                .asset-card-container {
                    width: 666px;

                    *,
                    *:before,
                    *:after {
                        box-sizing: inherit;
                    }

                    .asset-card {
                        border-radius: 16px;
                        width: 750px;
                        border: 2px solid var(--grey-20);
                        box-shadow: none;
                        padding: 15px;
                        min-height: 228px;

                        &.saved {
                            .asset-card-details {
                                margin-top: 20px;
                            }
                        }

                        .asset-card-header {
                            position: relative;
                            padding: 16px 0px 0px 0px;
                            display: flex;
                            border-top: solid 1px var(--grey-30);
                            justify-content: flex-end;

                            .asset-card-save {
                                border-radius: 8px;
                                width: 120px;
                                height: 50px;
                                font-size: 16px;
                                font-family: 'Roobert SemiBold', sans-serif;
                                line-height: 19.2px;
                                box-shadow: none;

                            }
                        }

                        .asset-card-body {
                            height: inherit;

                            margin-top: 0px;
                            justify-content: flex-start;
                            width: 583px;

                            &.saved {
                                min-height: 96px !important;

                                .asset-card-details {
                                    align-items: center;

                                    &>div:first-child {
                                        margin-top: 10px;
                                    }

                                    .asset-card-details-tags-summary {
                                        position: relative;
                                        top: 16px !important;
                                        left: 0px !important;

                                        .asset-card-details-tags {
                                            margin-left: 16px;
                                        }

                                    }
                                }
                            }

                            .asset-card-details {
                                position: relative;
                                width: 380px;
                                bottom: 20px;
                                padding: 0px 16px;
                                min-height: 116px !important;

                                .asset-card-details-tags-summary {
                                    display: flex;
                                    flex-direction: row;
                                    position: relative;
                                    flex-wrap: wrap;
                                    left: 18px;
                                    bottom: 25px;

                                    .tag-edit-button {
                                        right: 0px;
                                        position: absolute;

                                        p,
                                        svg {
                                            margin: 0px 3px;
                                            font-size: 16px;
                                            color: var(--green-everpass-40);
                                        }
                                    }

                                    .asset-card-details-tags {
                                        height: 100%;
                                        display: flex;
                                        width: 275px;
                                    }

                                    .asset-card-tag-container {
                                        width: 80px;
                                        position: relative;
                                        right: 18px;
                                        font-size: 14px;
                                        align-items: center;
                                        display: flex;
                                        justify-content: space-between;
                                        cursor: pointer;

                                        span {
                                            font-weight: 650;
                                            font-family: 'Roobert Regular', sans-serif;
                                            color: var(--green-everpass-40);
                                        }

                                        img {
                                            height: 16px;
                                            width: 16px;
                                        }
                                    }
                                }

                                button {
                                    position: absolute;
                                    top: 10px;
                                    left: 359px;
                                }

                                .asset-card-details-tags {
                                    flex-wrap: wrap;
                                    position: relative;
                                    right: 20px;
                                    margin-top: 0px;
                                    display: inline-block;
                                    width: 118px;
                                    height: 34px;
                                    align-items: center;

                                    .chip-tag {
                                        width: max-content;
                                        height: 34px;
                                        border-radius: 100px;
                                        gap: 8px;
                                        background-color: var(--green-everpass-10);
                                        color: var(--green-everpass-40);
                                        text-align: center;
                                        display: flex;
                                        justify-content: center;

                                        button {
                                            position: relative;
                                            margin-bottom: 20px;
                                            left: 5px;
                                            color: var(--green-everpass-40);
                                        }
                                    }


                                }

                                .tag-edit-button {
                                    position: relative;
                                }

                                input {
                                    padding: 15px 7px 0px 0px;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 20px;
                                    letter-spacing: 0.1px;

                                }



                                .MuiFormControl-root {
                                    margin-top: 25px;
                                    min-height: 96px;
                                    width: 366px;
                                    position: relative;
                                    bottom: 18px !important;

                                    .MuiFormControl-fullWidth {
                                        display: flex;
                                    }

                                    .MuiInput-root {
                                        margin-top: 35px !important;
                                        text-align: left;
                                    }

                                    label {
                                        padding-top: 0px;
                                        font-family: 'Roobert SemiBold', sans-serif;
                                        font-size: 15px;
                                        margin-top: 0px;
                                    }
                                }
                            }

                            .asset-card-uploader {
                                height: 96px !important;
                                width: 199px;

                                .asset-card-uploader-preview {
                                    height: 96px !important;
                                    width: 199px !important;
                                }

                                .asset-card-loader {
                                    height: 96px !important;
                                    width: 199px !important;
                                }
                            }
                        }
                    }
                }
            }

            .sidemenu {

                display: none;

                .asset-preview-drag-zone-item {

                    &:hover {
                        border: 2px, dashed var(--green-everpass-40)
                    }
                }
            }

            &:hover {
                border: 2px, dashed var(--green-everpass-40);
            }
        }
    }

    .TagManager .tag-container {
        width: 200px;

        .tag-search {
            width: 100%;
            border-radius: 100px;
            border-width: 1px;
            gap: 8px;
            padding: 12px;

            svg {
                display: none;
            }

        }


        .tag-list-container {
            min-height: 48px;
            align-items: center;
            display: flex;
            justify-content: space-between;
            padding: 0px 12px;

            .empty-state {
                width: 64px;
                text-align: left;
                font-style: normal;
                font-family: 'Roobert Regular', sans-serif;
                font-weight: 400;
                font-size: 12px;

            }

            .tag-chip {
                background-color: var(--green-everpass-40) !important;
                border: 1px solid var(--green-everpass-40);

                svg {
                    border-radius: 50%;
                    border: solid;
                    height: 12px;
                    width: 12px;
                }

                span,
                svg {
                    color: var(--green-everpass-10);
                }
            }
        }

        .tag-search-list .tag-search-list-item {
            padding: 8px 5px;

            .tag-check {
                color: var(--green-everpass-40);
            }

        }


        .tag-search .tag-search-input:focus {
            border-color: var(--green-everpass-40);
        }

        .add-new-tag {
            color: var(--green-everpass-40);
        }
    }

    .NewAssetLibrary {
        .NewAssetLibraryHeader {
            height: 108px;

            .light-button {
                border: 2px solid black;
                color: black;
                margin-right: 0px;
            }

            .header-title {
                position: absolute;
                left: 30px;
                font-weight: 650;
                font-size: 30px;
                line-height: 38.4px;
                letter-spacing: 0%;
                color: var(--black);
            }

            .button {
                border-radius: 8px;
                font-size: 16px;
                margin: 15px;

                &:hover {
                    background-color: var(--grey-80);
                }

                svg {
                    display: none;
                }
            }

            #go-to-collections-button {
                gap: 10px;
                border-width: 2px;
                border-radius: 6px;
                font-size: 16px;
                text-transform: capitalize;
                padding: 1px;

                &:hover {
                    background-color: var(--grey-30);
                }

                img {
                    display: none;
                }

            }
        }

        .NewAssetLibraryMain {
            min-height: calc(100vh - 66px - 85px); // header - topbar

            >.content .selected-tags {

                #create-collection-button {
                    color: var(--black) !important;
                    border-radius: 6px;
                    border-color: var(--green-everpass-10);
                    background-color: var(--green-everpass-10);
                }

                .tag-list {
                    .chip-tag {
                        background-color: var(--green-everpass-40);

                        button {
                            border: none;
                        }
                    }

                    .Slider {
                        .frame {
                            .content {
                                button {
                                    background-color: var(--green-everpass-40);
                                    color: var(--white);
                                }
                            }

                        }
                    }


                }
            }

            .chip-tag.clickable:hover {
                background-color: var(--green-everpass-10);
                border-color: var(--green-everpass-70);
            }

            .sidebar {
                .tags {
                    display: flex;
                    flex-wrap: wrap;
                    flex-direction: row;
                    margin-top: 0px;

                    .chip-tag {
                        background-color: var(--green-everpass-40);
                        color: var(--white);
                        margin: 5px 5px;

                        .count {
                            font-size: 10px;
                            background-color: var(--green-everpass-40);
                            border: 2px solid var(--white);
                            border-radius: 50%;
                            height: 22px;
                            width: 23px;
                        }
                    }

                }

                .tags-copy {
                    b {
                        font-family: 'Roobert SemiBold', sans-serif;
                        font-size: 18px;
                    }

                    p {
                        font-size: 14px;
                        font-family: 'Roobert Regular', sans-serif;
                        font-weight: 400;
                        line-height: 20px;
                        letter-spacing: 0.1px;
                        width: 276px;
                        height: 80px;

                    }
                }

                .search-box {
                    background-color: var(--grey-10) !important;
                    border-radius: 100px;
                    border: none;
                    height: 48px;
                    width: 308px;

                    &:active {
                        border: 1px solid var(--grey-60);

                    }

                    input {
                        color: var(--black);

                    }
                }

                .EmptyList {
                    width: 308px !important;

                    .image-container {
                        width: 48px;
                        height: 48px;
                        border-radius: 50px;
                        background-color: var(--grey-20);
                        align-items: center;
                        box-sizing: border-box;
                        display: inline-block;
                        margin-bottom: 20px;

                        img {
                            margin: 13px;
                            fill: black;
                            height: 24px;
                            width: 24px;
                        }

                    }

                    .title {
                        font-size: 18px;
                        margin-bottom: 10px;
                    }

                    .description {
                        width: 245px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15.17px;
                        text-align: center;
                        color: var(--black)
                    }

                }
            }

            .content {
                background-color: var(--white);

                .EmptyList {
                    gap: 10px;

                    .description {
                        min-width: 100% !important;
                        font-weight: 400;
                        font-size: 14px;
                        color: var(--black);
                    }

                    .image-container {
                        display: block;
                        background-color: var(--grey-20);
                        width: 80px;
                        height: 80px;
                        border-radius: 50px;
                        align-items: center;
                        box-sizing: border-box;
                        margin-bottom: 20px;

                        img {
                            width: 40px;
                            height: 40px;
                            margin: 19px;
                            margin-left: 22px;
                        }

                    }

                    .actions {
                        .button {
                            &:hover {
                                background-color: var(--grey-80);
                            }

                            border-radius: 8px;
                            width: 106px;
                            height: 51px;
                            gap: 16px;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 19.2px;
                        }

                        .button-description {
                            display: none;
                        }
                    }
                }

                .AssetsList .assets .AssetItem {
                    box-shadow: none;
                    border: solid 1px var(--grey-30);

                    .footer {
                        font-family: 'Roobert Regular', sans-serif;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19.2px;
                        letter-spacing: 0%;
                        color: var(--grey-50)
                    }

                    .thumbnail-container {
                        .thumbnail {
                            .icon.more {
                                color: var(--white);
                                right: 8px;
                                top: 8px;
                                background-color: #F5F5F54D;
                            }

                            .icon.assets-label-btn {
                                color: var(--white);
                                top: 8px;
                                left: 8px;
                                background-color: #F5F5F54D;
                            }
                        }

                        .thumbnail-button-container {
                            z-index: 9999;
                            position: relative;

                            .icon.more {
                                color: var(--white);
                                right: 8px;
                                top: 8px;
                                position: absolute;
                                background-color: #F5F5F54D;
                            }

                            .icon.assets-label-btn {
                                color: var(--white);
                                top: 8px;
                                left: 8px;
                                position: absolute;
                                background-color: #F5F5F54D;
                            }
                        }
                    }


                }
            }
        }

        .button {
            &:hover {
                background-color: var(--hover-color);
            }
        }
    }

    .EmptyList .actions .button {
        &:hover {
            background-color: var(--hover-color);
        }
    }

    //==========//

    //DIRECT SHARE

    .DirectShare,
    .SocialContent {
        .ToolBar {
            border-bottom: 1px solid var(--grey-20);

            .container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 99px;
                border-bottom: 10px;

                .title {
                    margin-left: 10px;
                    font-size: 23px;
                    font-weight: 650;
                    height: unset;
                }

                // Full black button
                .ConnectionWidget button {
                    font-family: 'Roobert SemiBold', sans-serif;
                    height: 51px;
                    font-size: 16px;
                    font-weight: 500;
                    border-radius: 6px;
                    padding: 18px 12px;
                    background-color: var(--black);
                    border: 2px solid var(--black);
                    color: white;

                    &:hover {
                        background-color: var(--grey-80);
                        border: 2px solid var(--grey-80);
                        color: var(--grey-10);
                    }

                    &:focus {
                        background-color: var(--grey-80);
                        border: 2px solid var(--black);
                        color: var(--grey-10);
                    }

                    &:active {
                        border: 2px solid var(--black);
                        background-color: var(--grey-80);
                        color: var(--grey-10);
                    }
                }

                .right-content .small-button.goBack {
                    height: 51px;
                    font-size: 16px;
                    font-weight: 500;
                    border-radius: 6px;
                    padding: 18px 12px;
                    background-color: white;
                    border: 2px solid var(--black);
                    color: var(--black);

                    &:hover {
                        background-color: var(--grey-30);
                    }

                    &:focus {
                        background-color: var(--grey-80);
                        color: var(--grey-10);
                    }

                    &:active {
                        background-color: var(--grey-50);
                        color: var(--grey-90);
                    }
                }
            }
        }

        .selectedContentBar {
            background-color: var(--grey-10);
            height: 99px;
            border-bottom: 1px solid var(--grey-20);
            display: flex;
            flex-direction: row;
            justify-content: left;
            align-items: center;
            font-family: "Roobert", sans-serif;

            .selectedContentCount {
                font-size: 16px;
                color: var(--grey-70);
                border-right: 2px solid var(--grey-50);
                height: 56px;
                padding-right: 16px;
                margin-left: 32px;
                margin-right: 16px;
                display: flex;
                align-items: center;

            }

            .deleteContent {
                font-size: 16px;
                font-weight: 650;
                color: #B71C1C;
                background-color: #FEEBEB;
                border-radius: 6px;
                height: 56px;
                padding: 16px 12px;
            }

            .clearContent {
                font-size: 16px;
                font-weight: 650;
                color: var(--green-everpass-40);
                background-color: unset;
                border-radius: 6px;
                height: 56px;
                padding: 16px 12px;
            }
        }

        .ContentList {
            background-color: white;
        }

        .content {
            background-color: white;
            display: flex;
            flex-direction: column;

            .title {
                visibility: hidden;
            }

            .card-container {
                height: 279px;
                width: 431px;

                .card {
                    background-color: unset;
                    background-color: white !important;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-evenly;
                    border: 2px solid var(--grey-50);
                    border-radius: 12px;

                    .icon-container {
                        margin: 0 150px;
                    }

                    .button {
                        &.primary {
                            color: var(--green-everpass-90);
                            height: 51px;
                            width: 158px;
                            background-color: var(--green-everpass-10);
                            border: 2px solid var(--green-everpass-10);
                            border-radius: 6px;
                            padding: 16px 12px 16px 12px;
                            font-family: "Roobert", sans-serif;
                            font-size: 16px;
                            font-weight: 500;

                            &:hover {
                                background-color: var(--green-everpass-20);
                                border: 2px solid var(--green-everpass-20);
                            }

                            &:focus {
                                background-color: var(--green-everpass-40);
                                border: 2px solid var(--green-everpass-10);
                                color: var(--green-everpass-10);
                            }

                            &:active {
                                background-color: var(--green-everpass-40);
                                border: 2px solid var(--green-everpass-90);

                            }

                        }

                        &.secondary {
                            color: var(--green-everpass-40);
                            height: 51px;
                            width: 158px;
                            background-color: white;
                            border: none;
                            border-radius: 6px;
                            padding: 16px 12px 16px 12px;
                            font-family: "Roobert", sans-serif;
                            font-size: 16px;
                            font-weight: 500;

                            &:hover {
                                background-color: unset;
                                background-color: white;
                            }

                            &:active {
                                color: var(--green-everpass-90);
                                background-color: unset;
                                background-color: #FFFFFF !important;
                            }
                        }
                    }
                }
            }

            .description {
                font-family: "Roobert", sans-serif;
                font-size: 20px;
                font-weight: 550;
                text-align: center;
                color: var(--grey-50);
            }

            .Dialog {
                .button .cancel {
                    border-radius: 6px;
                }

                .button .create {
                    border-radius: 6px;
                }
            }
        }
    }

    .ContentListItem.selected {
        border: 3px solid var(--green-everpass-40);
        border-radius: 10px;
    }

    .DirectShare .content {
        height: 100%;
    }

    .SocialContent {
        background-color: white;

        .content {
            background-color: white;
            min-height: 100%;
            height: unset;
        }
    }

    //==========//

    //AUDIO && PLAYLIST
    .PendoBadge .pendo-message .pendo-trigger {
        color: var(--green-everpass-40)
    }

    .SequenceHandler .sequence-chunk {
        &:hover {
            border: 1px solid var(--green-everpass-40) !important;
            box-shadow: 0 0 6px 0 var(--green-everpass-40)
        }

    }

    .menu-item-container .menu-item-click-handler:hover {
        background: var(--green-everpass-10);
    }

    .CreateDefaultSchedule .title {
        font-family: 'Roobert Normal', sans-serif;
    }

    .MixPanel {
        font-family: 'Roobert Normal', sans-serif;

        .default-section {
            margin-bottom: 12px;
        }

        .general-info-container .action-buttons .circle-button {
            border: none;
            background-color: #F5F5F5;

            &:hover {
                background-color: #D9D9D9;
                color: black;
                border: none;
            }
        }



        .default-section .set-default-mix-label {
            color: var(--green-everpass-70);
            font-family: 'Roobert SemiBold', sans-serif;
            font-size: 14px;

            &:hover {
                text-decoration: underline;
            }
        }

    }

    .MixesInfo .MixesList {
        position: relative;
        background-color: white;
        border-left: 1px solid #E9E9E9;

        .title {
            font-size: 21px;
            line-height: 25px;
            padding: 30px 32px 24px 32px;
            color: var(---black);
        }


        .paper-shadow {
            padding: 0 21px;
        }

        .EntryCard {
            &:hover {
                background-color: #F5F5F5;
                border-color: #C4C4C4;

                .details-container .details-title {
                    color: #555555
                }

            }
        }

        .button {
            font-family: "Roobert Regular", sans-serif;
            min-width: 100px;
            border-radius: 8px;
            position: absolute;
            right: 20px;
            top: 26px;
            padding: 0px 13px;
            height: 35px;
        }
    }




    .Editor {

        color: var(--black)
    }

    .Title .action-buttons .button.disabled {
        background-color: var(--black);
    }

    .Toolbox .tb-button-container .tb-button.selected {
        background-color: var(--green-everpass-70);
    }

    .DayPickerItem.active {
        background-color: var(--green-everpass-40);
    }

    .behavior-modal .behavior-container .behavior-item {

        &:hover,
        &.selected {
            border: 2px solid var(--green-everpass-40);
            box-shadow: 0 0 6px 0 var(--green-everpass-40);
        }
    }

    //===============PROMOTION START=================//

    .CampaignDetails {
        margin: unset;
        width: 100%;
    }

    .CampaignDashboard .spotlights-list .subtitle-bar .subtitle .indicator {
        color: var(--green-everpass-40);
    }

    .CampaignCreator {
        padding-left: 10px;
        background-color: unset
    }

    .CampaignHubSettings .modal-content .icons-container .IconSelector:hover {
        border: 1px solid var(--green-everpass-40);
    }

    .surveys .content .box-container .section .create-survey .accept-button,
    .surveys .content .box-container .section .create-survey .create-button {
        border: 2px solid black;
    }

    .SpotlightToolbox .tb-button-container .tb-button.selected {
        background-color: var(--green-everpass-70);
    }

    .Wizard {
        .stepper .step .step-indicator.active {
            border: 2px solid var(--green-everpass-40);
        }

        .content .actions .button.back {
            border: 2px solid black;
        }
    }

    .TypeSelector:hover,
    .TypeSelector.selected {
        border: 2px solid var(--green-everpass-40);
    }

    .Table>table>tbody tr:hover>td {
        background-color: var(--hover-color);
    }

    .ToolboxSection_title {
        color: var(--green-everpass-40);
    }

    .EditorHeader {

        .right-side {
            display: flex;
            flex-direction: row;
        }

        button {
            border: 2px solid black;
            border-radius: 6px;
            font-family: "Roobert SemiBold", sans-serif;
            height: 48px;
            display: flex;
            justify-content: center;
            font-size: 14px;

            &.circle-button {
                height: 50px;
                width: 50px;
                border-radius: 100px;
                border: none;
                color: var(--green-everpass-40);
                margin-right: 12px;
            }

            &.disabled {
                background-color: darkgrey;
                border: none;
            }
        }
    }


    .tb-button.selected,
    .tb-button.selected:hover {
        background-color: var(--green-everpass-70);
    }


    .filter-opened {
        border: 1px solid black;
        color: black;
    }

    .filter-icon:hover {
        border: 1px solid darkgrey;
        background-color: darkgrey;
    }

    .filter-check {
        color: black;
    }

    .EmptyState .buttons-container .button {
        font-family: 'Roobert SemiBold', sans-serif;
        font-size: 16px;
        font-weight: 500;
        border-radius: 6px;
        padding: 18px 12px;
        background-color: var(--black);
        border: 2px solid var(--black);
        color: white;

        &:hover {
            background-color: var(--grey-80);
            border: 2px solid var(--grey-80);
            color: var(--grey-10);
        }

        &:focus {
            background-color: var(--grey-80);
            border: 2px solid var(--black);
            color: var(--grey-10);
        }

        &:active {
            border: 2px solid var(--black);
            background-color: var(--grey-80);
            color: var(--grey-10);
        }
    }

    .everpass-pagination {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 10px;
        margin-top: 24px;

        .pages {
            display: flex;
            justify-content: center;
            align-items: center;

            .page,
            .total-pages {
                color: var(--black);
                margin-right: 30px;
                font-size: 16px;
            }

            .total-pages {
                margin-left: 30px;
            }
        }

        select {
            color: var(--green-everpass-40);
            font-size: 16px;
            border: 0;
            border-radius: unset;
        }

        button {
            width: 40px;
            height: 40px;
            border-radius: 100px;
            border: none;
            background-color: var(--grey-20);
            color: var(--grey-70);
            cursor: pointer;
            font-family: "Roobert SemiBold", sans-serif;
            display: flex;
            justify-content: center;
            align-items: center;

            &:disabled {
                opacity: .5;
                cursor: default;
            }
        }


        .next-icon {
            position: relative;
            left: 1px;
        }

        .previous-icon {
            position: relative;
            left: 3px;
        }
    }

    .filter-modal {
        border-radius: 8px;

        .header .header-text {
            font-family: "Roobert Bold", sans-serif;

        }

        .content {
            .section-title {
                color: var(--grey-50);
            }

            .filter-check-name {
                font-size: 14px;
            }
        }

        .filter-status {
            padding-bottom: 10px;
        }

        .footer {
            justify-content: flex-end;

            button {
                border: 2px solid black;
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                display: flex;
                justify-content: center;
                font-size: 14px;
            }

            button:hover {
                background-color: var(--grey-80);
            }

            .btn-clear-filter {
                width: fit-content;
                margin-right: 18px;
                padding-left: 4px;
                padding-right: 4px;
                font-family: "Roobert SemiBold", sans-serif;
                color: var(--green-everpass-40);
            }
        }
    }

    .campaigns-manager {
        padding: 24px;
    }

    .promotion-Spotlights-tab,
    .fixed-panel-tab,
    .promotion-campaign-hub-tab,
    .promotion-qrCampaigns-tab {
        font-family: "Roobert Regular", sans-serif !important;
        font-size: 13px;
        text-transform: none;
        font-weight: 200;
    }

    .campaigns-manager,
    .PromotionsHeader {

        .selection-actions .button {

            &.disable,
            &.enable {
                border: 2px solid var(--black);
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                height: 48px;
                display: flex;
                justify-content: center;
                font-size: 14px;

                .icon {
                    display: none;
                }
            }

            &.delete {
                border: none;
                background-color: var(--light-red);
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                height: 48px;
                display: flex;
                justify-content: center;
                font-size: 14px;

                .icon {
                    display: none;
                }
            }
        }

        .filter-actions {
            flex-direction: row-reverse;
        }

        .filter-actions .filter-icon {
            padding: 8px;
            border-radius: 100px;
            background-color: var(--grey-10);
            border: none;
            margin-right: 15px;
            margin-left: 0;
            box-shadow: none;

            &:hover {
                background-color: var(--grey-20);
            }

            &.filter-opened,
            &:active {
                color: #ffff;
                background-color: var(--green-everpass-40);
            }
        }

        .search-box {
            background-color: var(--grey-10);
            border-radius: 100px;
            border: none;
            display: flex;
            flex-direction: row-reverse;

            .MuiFormControl-root {
                box-sizing: border-box;
                margin-top: 0;
            }

            &:hover {
                background-color: var(--grey-20);
            }

            .input {
                color: var(--grey-90);
                font-family: "Roobert Regular", sans-serif;
            }
        }

        .campaigns-manager-table-header {
            .search-box {
                background-color: var(--grey-10);
                border-radius: 100px;
                border: none;
                display: flex;
                flex-direction: row-reverse;

                .input {
                    font-family: "Roobert Regular", sans-serif;
                }

                .css-1laqsz7-MuiInputAdornment-root {
                    margin-left: 0;
                    margin-right: 8px;
                    color: var(--grey-90);
                }

                .MuiFormControl-root {
                    box-sizing: border-box;
                    margin-top: 0;
                }

                &:hover {
                    background-color: var(--grey-20);
                }
            }
        }

        .search-box:has(input:focus) {
            background-color: var(--white);
            border: 1px solid var(--grey-70)
        }

        .campaigns-manager-button-actions {
            display: flex;
            flex-direction: row;
        }

        .campaigns-manager-table-header.withoutTitle .button,
        .campaigns-manager-table-header.withoutTitle .button.report {
            border: 2px solid black;
            border-radius: 6px;
            font-family: "Roobert SemiBold", sans-serif;
            height: 48px;
            display: flex;
            justify-content: center;
            font-size: 14px;

            .css-ptiqhd-MuiSvgIcon-root {
                display: none;
            }
        }

        .create-buttons button {
            border: 2px solid black;
            border-radius: 6px;
            font-family: "Roobert SemiBold", sans-serif;
            height: 48px;
            width: 144px;
            display: flex;
            justify-content: center;
            font-size: 14px;
            padding: 16px 14px;

            .icon {
                display: none;
            }

            button:hover {
                background-color: var(--grey-90);
            }
        }
    }

    a:link {
        color: var(--green-everpass-40);
    }

    .everpass-audio {
        width: 100%;
        overflow: hidden;

        .ToolBar {
            width: 100%;
            border-bottom: 1px solid var(--grey-30);
            padding: 0 48px;
        }
    }

    .NewSchedule {

        .button.containedPrimary {
            border: none;
            border-radius: 6px;
            font-family: "Roobert SemiBold", sans-serif;
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: var(--white);
            background-color: var(--black);
        }

        .button.textPrimary {
            border: 2px solid var(--black);
            border-radius: 6px;
            font-family: "Roobert SemiBold", sans-serif;
            display: flex;
            justify-content: center;
            font-size: 14px;
            color: var(--black);
            text-decoration: none !important;
        }

        .button.textPrimary:disabled,
        .button.containedPrimary:disabled {
            opacity: 0.3;

        }

        .create-button {
            padding-bottom: 32px;
            display: flex;
            justify-content: flex-end;
            gap: 16px;
            margin: 0;
        }

        .DayPicker {
            display: flex;
            justify-content: space-around;

            .DayPickerItem {
                width: 48px;
                height: 48px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    .schedule-modal {

        .schedule-detail .icon-delete {
            width: 16px;
            height: 16px;
        }

        .checkbox-label>span {
            font-family: "Roobert SemiBold", sans-serif;
        }

        .audio-schedule-container {
            margin-top: 16px;
            box-shadow: none;
            border: 1px solid var(--grey-30);
            width: 90%;
            padding: 16px;
            border-radius: 8px;

            .schedule-text {
                font-weight: 400;
                font-size: 14px;
                line-height: 16.8px;
                color: var(--grey-50);
            }

            .schedule-title-wrapp {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 24px;

                .schedule-title {
                    font-family: "Roobert SemiBold", sans-serif;
                    font-weight: 750;
                    font-size: 20px;
                    line-height: 20px;
                }

                .schedule-add-button {
                    padding: 0;
                    font-family: "Roobert Regular", sans-serif;
                    font-weight: 650;
                    font-size: 16px;
                    line-height: 19.2px;
                    color: var(--green-everpass-40);
                }

                .schedule-add-button:disabled {
                    padding: 0;
                    font-family: "Roobert Regular", sans-serif;
                    font-weight: 650;
                    font-size: 16px;
                    line-height: 19.2px;
                    color: var(--grey-50);
                    background-color: transparent;
                    border: none;
                }

            }
        }
    }




    .schedules-container {
        .empty-state {
            .empty-icon-wrapp {
                width: 48px;
                height: 48px;
                border-radius: 100%;
                background-color: var(--grey-10);
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 16px;

                .empty-icon {
                    color: var(--black);
                    width: 24px;
                    height: 24px;
                }
            }

            .empty-title {
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 650;
                font-size: 18px;
                line-height: 21.6px;
                color: var(--black);
            }

            .empty-text {
                font-weight: 400;
                font-size: 12px;
                line-height: 15.17px;
                color: var(--black);
            }
        }

        .title {
            font-family: "Roobert SemiBold", sans-serif;
            text-transform: capitalize;
            font-weight: 650;
            font-size: 14px;
            line-height: 16.8px;
            text-align: start;
            color: var(--black);
        }

        .schedule-container {
            border-left-color: var(--local);
            color: var(--black);
        }
    }


    .behavior-modal {
        .actions {
            justify-content: flex-end;
            gap: 16px;
        }

        .behavior-container .behavior-item {
            box-shadow: none;
            border: 1px solid var(--grey-30);
        }

        .behavior-container .behavior-item:hover,
        .behavior-container .behavior-item.selected {
            box-shadow: none;
            border: 1px solid var(--green-everpass-70);
        }

        .title {
            font-size: 29px;
            line-height: 34.8px;
            margin-bottom: -24px;
        }

        .description {
            font-weight: 400;
            font-size: 14px;
            line-height: 16.8px;
            color: var(--black);
        }

        .behavior-container .behavior-item .item-label .item-text .item-title {
            font-family: "Roobert SemiBold", sans-serif;
        }
    }

    .AudioUploader .dropzone-container .dropzone {
        .dropzone-title {
            color: var(--black);
        }

        .dropzone-wrapp {
            width: 40px;
            height: 40px;
            border-radius: 100%;
            background-color: var(--grey-10);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 16px;

            .dropzone-icon {
                color: var(--black);
                width: 24px;
                height: 24px;
            }
        }
    }

    .create-modal {
        .title {
            margin-top: 40px;
        }

        .generate-buttons-container {
            .select {
                font-family: "Roobert SemiBold", sans-serif;
                font-size: 14px;
            }

            .voice-selector-wrapp {
                width: 65%;

                .select-voice {
                    width: 100%;
                }
            }
        }

        input {
            font-family: "Roobert Regular", sans-serif;
            color: var(--black);
        }

        .type-label {
            font-family: "Roobert Regular", sans-serif;
            font-weight: 400;
            font-size: 16px;
            line-height: 18px;
        }

        .actions {
            justify-content: flex-end;
            gap: 16px;
        }

        .create-container {

            .input-label {
                font-family: "Roobert SemiBold", sans-serif;
            }

            .audio-input-wrapp {
                width: 100%;
                margin-bottom: 24px;


            }

            .create-name {
                font-family: "Roobert SemiBold", sans-serif;
            }

            .MuiFormHelperText-root {
                color: var(--grey-60);
                display: flex;
                justify-content: flex-end;
            }

            .checkbox-label .label {
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: var(--black);
            }
        }
    }

    .settings-modal {
        .title {
            margin-top: 40px;
        }

        .actions {
            display: flex;
            justify-content: flex-end;
            gap: 16px;
        }

        .label {
            font-family: "Roobert SemiBold", sans-serif;
        }

        .content {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;

            .content-input {
                width: 45%;
                display: flex;
                flex-direction: column;

                input {
                    width: 53px;
                }
            }
        }
    }

    .audio-select {
        scrollbar-width: none;
        border-right: 1px solid var(--grey-30);
        max-width: none;

        .premium-audio {
            .channel {
                border: 1px solid var(--grey-30);
                border-radius: 16px;
                margin-bottom: 8px;
                height: 100px;
                padding: 8px 16px;

                .channel-right {
                    .settings {
                        background-color: var(--grey-10);
                        width: 40px;
                        height: 40px;

                        .editIcon {
                            width: 20px;
                            height: 20px;
                        }
                    }

                    .row-artists>p:nth-child(2) {
                        color: var(--black);
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15.17px;
                        text-transform: capitalize;
                    }

                    .row-artists>p:first-child {
                        font-family: "Roobert SemiBold", sans-serif;
                        font-weight: 650;
                        font-size: 14px;
                        line-height: 16.8px;
                    }
                }

                .channel-left {
                    .channel-info {
                        .channel-title {
                            font-family: "Roobert SemiBold", sans-serif;
                            font-weight: 650;
                            font-size: 16px;
                            line-height: 19.2px;
                        }

                        .channel-desc {
                            color: var(--black);
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 16.8px;
                        }
                    }

                    .channel-icon {
                        width: 95px;
                        height: 73px;
                    }
                }
            }
        }

        .mood {
            height: 73px;
            background-color: var(--grey-10);
            padding: 0;
            width: 95px;

            .Slider .frame {
                overflow: visible;
            }

            .info {
                .editIcon {
                    width: 16px;
                    height: 16px;
                    padding: 0 3px 4px 0;
                    cursor: pointer;
                }

                .name {
                    font-weight: 650;
                    font-size: 10px;
                    line-height: 12px;
                    padding-left: 4px;
                }
            }

            .icon {
                width: 100%;
                height: 49px;
            }
        }

        .premium-banner {
            width: 650px;
        }

        .title {
            font-family: "Roobert Regular", sans-serif;
            font-weight: 650;
            font-size: 20px;
            line-height: 24px;
        }

        .text {
            font-size: 16px;
        }
    }

    .settings-widget {
        box-shadow: none;
        border: 1px solid var(--grey-20);

        .setting {
            min-height: 45px;

            .content {
                .title {
                    font-family: "Roobert SemiBold", sans-serif;
                    font-weight: 650;
                    font-size: 14px;
                    line-height: 16.8px;
                    text-transform: capitalize;
                }

                .behavior {
                    color: #000000;
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 15.17px;
                }
            }

            .actions-default {
                padding-top: 2px;
                font-weight: 400;
                font-size: 12px;
                line-height: 15.17px;
                color: #000000;
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                .button {
                    border: none;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    width: fit-content;
                    min-width: 34px;

                    .editIcon {
                        width: 24px;
                        height: 24px;
                    }
                }

                .default {
                    color: var(--green-everpass-40);
                }
            }
        }
    }

    .audio-announcements-container {
        box-shadow: none;
        border: 1px solid var(--grey-20);

        .button-create {
            color: var(--white);
            background-color: var(--black);
            text-transform: none;
            font-weight: 650;
        }

        .empty-icon-wrapp {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 48px;
            height: 48px;
            background-color: var(--grey-10);
            border-radius: 100%;

            .empty-icon {
                width: 24px;
                height: 24px;
            }
        }

        .empty-state {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            padding-bottom: 16px;

            .empty-title {
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 650;
                font-size: 16px;
                line-height: 19.2px;
            }

            .empty-text {
                font-weight: 400;
                font-size: 12px;
                line-height: 15.17px;
                letter-spacing: 0.1px;
                color: var(--black);
            }
        }

        .header {
            .buttons .button.settings {
                border: none;
                margin-top: -8px;

                .icon {
                    width: 24px;
                }
            }

            .title {
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 650;
                font-size: 14px;
                line-height: 16.8px;
            }
        }
    }

    .main-scroll-container {
        overflow: auto;
        max-height: calc(100vh - 84px);
        justify-content: flex-start;
    }

    .ListDevices {

        .pagination {
            width: 100%;
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 24px;

            .pages {
                display: flex;
                justify-content: center;
                align-items: center;

                .page,
                .total-pages {
                    color: var(--black);
                    margin-right: 30px;
                    font-size: 16px;
                }

                .total-pages {
                    margin-left: 30px;
                }
            }

            select {
                color: var(--green-everpass-40);
                font-size: 16px;
                border: 0;
                border-radius: unset;
            }

            button {
                width: 40px;
                height: 40px;
                border-radius: 100px;
                border: none;
                background-color: var(--grey-20);
                color: var(--grey-70);
                cursor: pointer;
                font-family: "Roobert SemiBold", sans-serif;
                display: flex;
                justify-content: center;
                align-items: center;

                &:disabled {
                    opacity: .5;
                    cursor: default;
                }
            }


            .next-icon {
                position: relative;
                left: 1px;
            }

            .previous-icon {
                position: relative;
                left: 3px;
            }
        }

        .container {

            .TitleBar .buttons .button {
                border: 2px solid black;

                &.disabled {
                    border-color: black;
                    background-color: black;
                }
            }

            .devices-tabs {

                border: none;
                border-bottom: 1px solid var(--grey-20);

                .selected-tab .TabLabel {
                    color: var(--secondary-color);
                    font-family: "Roobert SemiBold", sans-serif;
                    font-weight: 750;
                }

                .TabLabel {
                    color: var(--grey-90);
                    font-size: 14px;
                    font-weight: 400;
                    text-transform: none;
                    display: flex;

                    .counter {
                        border-radius: 0;
                        justify-content: center;
                        align-items: center;
                        padding: 1px;
                        width: 16px;
                        height: 16px;

                        &.all,
                        &.secure {
                            background-color: var(--green-everpass-10);
                            color: var(--green-everpass-90);
                            font-weight: 650;
                            font-size: 13px;

                        }

                        &.online {
                            background-color: #57C7A0;
                            font-weight: 650;
                            font-size: 13px;
                        }

                        &.offline,
                        &.pending {
                            font-weight: 650;
                            font-size: 13px;
                        }
                    }


                }
            }

            .table-container {

                .pagination button,
                .Table .pagination button {
                    border: 2px solid #000000;
                    background-color: #000000;
                }

                table {
                    input[type="checkbox"] {
                        accent-color: var(--green-everpass-40);
                    }

                    tbody {
                        tr {
                            background-color: var(--white);
                            border-bottom: 1px solid var(--grey-10);
                        }

                        tr:hover {
                            background-color: var(--grey-10);
                        }
                    }
                }
            }

            .sub-description {
                font-size: 18px;
                font-weight: 500;
                line-height: 21.6px;
                text-align: left;
                color: var(--grey-50);
                background-color: var(--white);
                padding-bottom: 16px;
                width: 55%;
            }

            .actions {

                .filters {
                    .search-box {
                        background-color: var(--grey-10);
                        border-radius: 100px;
                        border: none;
                        display: flex;
                        flex-direction: row-reverse;
                        width: 100%;
                        height: 48px;

                        .css-1laqsz7-MuiInputAdornment-root {
                            margin-left: 0;
                            margin-right: 8px;
                            color: var(--grey-90);
                        }

                        .MuiFormControl-root {
                            box-sizing: border-box;
                            margin-top: 0;
                        }

                        &:hover {
                            background-color: var(--grey-20);
                        }

                        .input {
                            color: var(--grey-90);
                            font-family: "Roobert Regular", sans-serif;
                        }
                    }

                    .search-box:has(input:focus) {
                        background-color: var(--white);
                        border: 1px solid var(--grey-70)
                    }
                }

                button {
                    border: none;
                    border-radius: 6px;
                    font-family: "Roobert SemiBold", sans-serif;
                    font-weight: 650;
                    height: 48px;
                    display: flex;
                    justify-content: center;
                    font-size: 14px;
                    color: var(--green-everpass-90);
                    background-color: var(--green-everpass-10);
                    padding: 16px 14px;

                    &.bandwidth-button {
                        .icon {
                            display: none;
                        }
                    }

                    &.speed-button {
                        .icon {
                            display: none;
                        }
                    }

                    &.stop-test-stream {
                        color: var(--dark-red);
                        background-color: var(--light-red);
                    }

                    &.stop-test-stream:hover {
                        color: var(--white);
                        background-color: var(--red);
                    }


                    &.offline-filter {
                        border-radius: 100px;
                        background-color: var(--grey-10);
                        border: none;
                        width: 48px;
                        height: 48px;
                        padding: 12px;

                        .offline-filter-icon {
                            width: 24px;
                            height: 24px;
                        }

                        &:hover {
                            background-color: var(--grey-20);
                        }

                        &.filter-opened,
                        &:active {
                            color: #ffff;
                            background-color: var(--green-everpass-40);
                        }
                    }
                }

                button:hover {
                    background-color: var(--green-everpass-20);
                }

                button:disabled {
                    opacity: .3;
                }

                button:active {
                    background-color: var(--green-everpass-40);
                    border: 2px solid var(--green-everpass-40);
                }

                .filter-menu {
                    .filter-menu-item {
                        font-family: "Roobert Regular", sans-serif;
                    }
                }
            }
        }

        .ToolBar .title {
            font-weight: 650;
            font-family: "Roobert SemiBold", sans-serif;
        }

        .ToolBar .right-content .controls {
            display: flex;
            flex-direction: row-reverse;

            button {
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                font-weight: 650;
                height: 48px;
                display: flex;
                justify-content: center;
                font-size: 14px;
                padding: 16px 14px;

                &.run-test-stream {
                    border: none;
                    color: var(--green-everpass-90);
                    background-color: var(--green-everpass-10);
                }

                &.run-test-stream:hover {
                    background-color: var(--green-everpass-20);
                }


                &.run-test-stream:active {
                    background-color: var(--green-everpass-40);
                    border: 2px solid var(--green-everpass-40);
                }

                &.stop-test-stream {
                    border: none;
                    color: var(--dark-red);
                    background-color: var(--light-red);
                }

                &.stop-test-stream:disabled,
                &.run-test-stream:disabled {
                    opacity: .3;
                }

                &.stop-test-stream:hover {
                    color: var(--white);
                    background-color: var(--red);
                }
            }

            .export-data-button {
                border-radius: 6px;
                font-family: "Roobert SemiBold", sans-serif;
                height: 48px;
                display: flex;
                justify-content: center;
                font-size: 14px;
                padding: 16px 14px;

                .icon {
                    display: none;
                }
            }

            .activate-device-button {
                border: none;
                color: var(--white);
                background-color: black;
                border-radius: 6px;
                height: 48px;
                font-size: 14px;
                font-family: "Roobert SemiBold", sans-serif;
                padding: 16px 14px;
            }

            .activate-device-button:hover {
                background-color: var(--grey-80);
            }

            .activate-device-button:disabled {
                opacity: 0.3;
            }

        }
    }

    .business-hours .content .box-container .section .subtitle {
        color: #000000;
    }

    .Brandkit .content .box-container .section .subtitle {
        color: #000000;
    }

    .General .content .box-container .section .subtitle {
        color: #000000;
    }

    .Home {
        flex-direction: column;

        .page-container {
            flex-direction: row;
        }

        .TopBarEverpass {
            background-color: #000000;
            border-bottom: none;
            padding-left: 32px;
            z-index: 100;

            .disabled-preview {
                background: white;
            }

            .everpass-logo {
                width: 140px;
            }

            .everpass-menu {
                font-weight: 100;
                color: var(--grey-50);
                display: flex;
                column-gap: 32px;
                padding: 0 44px;
                margin-left: 15px;
                font-family: "Roobert Regular", sans-serif;

                >div {
                    cursor: pointer;
                }

                a {
                    color: inherit;
                    text-decoration: inherit;


                    &:hover {
                        color: var(--grey-10)
                    }

                    &:active {
                        color: white;
                        font-weight: bold;
                    }
                }

                .active {
                    color: white;
                    font-weight: bold;
                }
            }

            .breadcrumb {
                display: none;
            }

            .top-menu {
                background-color: #262626;

                .middle {

                    .organization,
                    .username {
                        font-family: "Roobert Regular", sans-serif;
                        color: white;
                    }
                }
            }

            .content {
                height: 84px;

                .right-content {

                    a:visited,
                    a {
                        width: 40px;
                        height: 40px;
                        border-radius: 100px;
                        color: var(--grey-30);
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        .icon {
                            position: relative;
                        }
     
                    }

                    a:hover {
                        color: var(--grey-10);
                    }

                    .active-link,
                    a:active {
                        color: var(--white);
                        background-color: var(--grey-80);
                    }

                    .top-menu {
                        border: none;

                        .middle {

                            .organization,
                            .username {
                                font-family: "Roobert Regular", sans-serif;
                                font-weight: 100;
                            }
                        }

                        .right {
                            .icon {
                                color: white;
                            }

                            .opened {
                                color: white;
                            }
                        }
                    }
                }

            }



        }
    }
}


.EverpassUserMenu {
    margin-top: 20px;
    z-index: 100;

    .MuiPaper-root {
        border-radius: 24px;
    }

    .content {
        box-sizing: border-box;
        width: 444px;
        display: flex;
        flex-direction: column;
        max-height: 624px;
        padding: 20px;

        .top {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 45px;
            margin-bottom: 24px;

            .left {
                margin-right: 8px;
                display: flex;
                align-items: center;
            }

            .middle {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
            }

            .right {
                margin-left: auto;
                display: flex;
                align-items: center;
                gap: 10px;

                a {
                    display: flex;
                    align-items: center;
                }
            }

            .name-container {
                font-weight: normal;
                font-style: normal;
                font-stretch: normal;
                text-align: left;
                color: var(---black);
                width: 280px;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                gap: 4px;

                .name {
                    margin: 0;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-family: "Roobert Bold", sans-serif;
                    font-size: 14px;
                }
            }

            .username {
                font-family: "Roobert Regular", sans-serif;
                font-size: 11px;
                font-weight: 400;
                line-height: 13.2px;
                color: var(--grey-70);
                margin-top: 4px;
            }

            .org-id {
                font-family: "Roobert Regular", sans-serif;
                font-size: 14px;
                color: var(--grey-70);
            }

            .avatar {
                width: 56px;
                height: 56px;
                border-radius: 30px;
                object-fit: cover;
            }

            hr {
                border-color: var(--grey-20);
                padding: 0;
            }

            a,
            button {
                color: var(---black);
                margin: 0;
                padding: 0;
                min-width: 0;
            }

            .edit {
                color: white;
                margin-left: 5px;
            }
        }

        .bottom {
            display: flex;
            justify-content: center;
            overflow-y: auto;

            .content-account {
                padding-left: 4px;
                padding-right: 4px;
                display: flex;
                flex-direction: column;
            }

            .OrganizationName {
                height: 21px;
                font-size: 16px;
                font-weight: bold;
                line-height: 1.25;
                color: white;
                display: flex;
                align-items: center;

                .small_link {
                    margin-left: 5px;
                }

                .launch {
                    margin-left: 3px;
                    color: white;
                }
            }

            .buttons {
                margin-top: 26px;
                display: flex;
                justify-content: space-between;

                button {
                    border-radius: 22px;
                    height: 44px;
                    padding: 0 25px;
                    font-size: 12px;
                    font-weight: bold;
                    line-height: 1.67;
                    color: white;
                    background-color: var(--white-two);
                    font-family: "Roobert Regular", sans-serif;
                }
            }
        }


        .small_link {
            font-size: 8px;
            color: white;
            text-decoration: underline;
            font-family: "Roobert Regular", sans-serif;
        }

        .small_text_10 {
            font-family: "Roobert Regular", sans-serif;
            line-height: 20px;
            font-size: 10px;
            color: var(--grey-70);
        }
    }
}

.everpassAccountModal {
    width: 100%;
    display: flex;
    justify-content: center;

    .content-account {
        margin: 0;
        width: 100%;

        .close {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.88;
            color: var(--white);
            position: absolute;
            top: -32px;
            right: 5px;
            text-decoration: underline;

            &:focus {
                outline: none;
            }
        }

        .header {
            display: flex;

            .title {
                font-family: "Roobert Regular", sans-serif;
                font-size: 24px;
                font-weight: bold;
                line-height: 0.83;
                color: white;
            }

            .switch_link {
                margin-left: 10px;
                font-size: 8px;
                line-height: 1.25;
                color: white;

                span {
                    font-weight: bold;
                    color: white;
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        input {
            margin-top: 0 !important;
            font-family: "Roobert Regular", sans-serif;
            font-size: 14px;
            padding: 10px 16px;
            color: var(---black);
        }

        .org-tree-search {
            background-color: var(--grey-10);
            border-radius: 100px;

            .MuiFormControl-root {
                box-sizing: border-box;
                margin-top: 0;
            }

            .MuiOutlinedInput-notchedOutline {
                border: none;
            }

            &:hover {
                background-color: var(--grey-20);
            }

            .search-input {
                color: var(--grey-90);
                font-family: "Roobert Regular", sans-serif;

                &::placeholder {
                    color: var(--grey-90);
                }
            }

        }

        .org-tree-search:has(input:focus) {
            background-color: var(--white);
            border: 1px solid var(--grey-70)
        }

        .org-tree-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: var(---black);
            margin: 12px 0;

            h2 {
                font-size: 23px;
                margin: 0;
                font-weight: 500;
            }

            .checkbox-container {
                display: flex;
                align-items: center;
                font-size: 14px;
                color: var(--grey-80);
                font-family: "Roobert Light", sans-serif;

                .MuiButtonBase-root {
                    padding: 8px
                }
            }
        }

        .scrollableView {
            overflow-y: auto;
            display: flex;
            border-radius: 12px;
            border: 1px solid var(--grey-20);

            .orgDivider {
                background-color: rgb(240, 238, 238);
                color: rgba(0, 0, 0, 0.75);
                display: block;
                margin-bottom: 12px;
                height: 1px;
                width: 317px;
            }

            .saved {
                padding: 0;

                .title {
                    font-family: "Roobert Regular", sans-serif;
                    width: 102px;
                    height: 23px;
                    font-size: 9px;
                    line-height: 1.22;
                    padding-left: 0;
                    color: var(--brownish-grey);
                }
            }

            .all {
                .title {
                    font-family: "Roobert Regular", sans-serif;
                    padding-top: 25px;
                    width: 102px;
                    height: 23px;
                    font-size: 9px;
                    color: var(--brownish-grey);
                }
            }
        }
    }
}

.everpass .scrollableView .OrganizationTree .OrgListItem {

    &.empty-state {
        color: var(--grey-90);
        font-family: "Roobert Regular", sans-serif;
    }

    &.selected .itemContent a {
        color: var(--green-everpass-70);
    }

    &.selected {
        background-color: var(--green-everpass-10);
        color: var(---black);

        .itemContent .check {
            color: var(--green-everpass-70);
        }
    }

    .itemContent {
        color: var(---);

        a {
            color: var(--grey-80);
        }

        .star {
            color: var(--green-everpass-70);
        }
    }
}

.everpass {

    .DayPicker-Day {
        color: var(--black);
        font-size: 12px;
        font-family: "Roobert Regular", sans-serif;
    }

    .DayPicker-Day--today {
        color: var(--brownish-grey);
    }

    .DayPicker-Day--disabled {
        color: var(--dark-slate-blue-60);
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
        background-color: var(--green-everpass-40);
    }

    .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
        background-color: var(--green-everpass-10);
    }

    .Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
        background-color: var(--green-everpass-10);
        color: var(--black);
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
        background-color: var(--green-everpass-70);
        position: relative;
        border-radius: 50% !important;
    }

    .DayPicker-Weekday abbr[title] {
        color: var(--black);
        font-family: "Roobert SemiBold", sans-serif;
        font-size: 12px;
        font-weight: bold;
        line-height: 15px;
        text-align: center;

    }

    .DayPicker-Caption {
        border-bottom: solid 1px var(--very-light-gray);
        padding-bottom: 12px;
        margin-bottom: 3px;

        

        >div {
            color: var(--black);
            font-family: "Roobert SemiBold", sans-serif;
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
            text-align: center;
        }
    }
}