.SideBar {
    --sidebar-header-primary-color: #060C33;
    --sidebar-header-secondary-color: #060C33;
    --sidebar-header-text-color: white;
    --sidebar-bg-color: #060C33;
    --sidebar-bg-color-connect: #262626;
    --sidebar-gradient: linear-gradient(0deg, var(--sidebar-header-secondary-color) 0%, var(--sidebar-header-primary-color) 100%);
    --sidebar-border-radius: 25px;
    --sidebar-transition: width 0.3s linear;

    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    transition: var(--sidebar-transition);
    width: 172px;
    min-height: 100%;
    font-family: 'Cabin', sans-serif;
    z-index: 100;
    
    &.connect {
        --sidebar-header-primary-color: #17B0F4;
        --sidebar-header-secondary-color: #007d68;
        --sidebar-header-text-color: white;

        .DrawerHeaderContainer {
            background-color: var(--sidebar-bg-color-connect);
            
            .DrawerHeader {
                background: linear-gradient(90deg, rgba(0,125,104,1) 27%, rgba(0,51,34,1) 98%);
                background-image: transparent;
                .experience-badge {
                    bottom: 21px;
                }
    
                .experience-logo {
                    width: 100%;
                }
            }
        }

        .DrawerContent,  .DrawerHeaderContainer {
            background-color: var(--sidebar-bg-color-connect);
        }
    }

    &.health {
        --sidebar-header-primary-color: #00EE9C;
        --sidebar-header-secondary-color: #029271;
        --sidebar-header-text-color: #060C33;
    }

    &.empty {
        --sidebar-border-radius: 0px;
        --sidebar-header-color: var(--sidebar-bg-color);
        --sidebar-header-text-color: white;
    }


    .DrawerHeaderContainer {
        position: relative;
        background-color: var(--sidebar-bg-color);

        .DrawerHeader {
            overflow-x: hidden;
            position: relative;
            box-sizing: border-box;
            width: 100%;
            height: 102px;
            padding: 4px;

            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            border-bottom-right-radius: var(--sidebar-border-radius);
            background-image: var(--sidebar-gradient);
            background-size: auto 100%;
            background-repeat: no-repeat;

            a {
                display: flex;
                justify-content: center;
            }

            .header-toggle-btn {
                position: absolute;
                top: 4px;
                right: 4px;

                width: 24px;
                height: 24px;
            }

            .header-toggle-icon {
                color: var(--sidebar-header-text-color);
                width: 18px;
            }

            .experience-logo,
            .experience-badge {
                animation: fadeIn 0.3s ease-in-out;
                position: absolute;
            }

            .experience-logo {
                width: 115px;
                top: 38px;
            }

            .experience-badge {
                width: 45px;
                bottom: 32px;
            }
        }
    }

    .DrawerContentContainer {
        flex-grow: 1;
        flex-shrink: 0;

        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .DrawerContent {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: calc(100% - 4px);
        height: 100%;

        background-color: var(--sidebar-bg-color);
        border-top-left-radius: var(--sidebar-border-radius);
    }

    .open {
        justify-content: flex-end;
        padding-right: 0;
    }

    .subHeader {
        font-family: 'Cabin', sans-serif;
        font-weight: bold;
        font-size: 10px;
        color: var(--gray);
        margin-top: 10px;
        line-height: 30px;
        margin-left: -6px;
        text-align: center;
        width: 76px;
        text-transform: uppercase;
    }

    .spacer {
        margin-top: 80px;
    }

    .menu-item {
        box-sizing: border-box;
        text-transform: uppercase;
        align-items: center;

        .list-item-icon {
            margin: 0 8px;
            min-width: 30px;

            &.open {
                margin: 0;
            }
        }

        .assets-icon {
            fill: white;
        }

        .iconColor {
            color: white;
            margin-left: -3px;
        }

        .iconText {
            font-family: 'Cabin', sans-serif;
            color: white;
            font-size: 12px;
            margin-left: 15px;

            .indicator {
                margin-left: 8px;
                color: white;
                background-color: #DE425B;
                padding: 2px 4px;
                border-radius: 2px;
                font-size: 10px;
            }

        }

        &:not(.placeholder):focus,
        &:not(.placeholder):hover, {
            background-color: rgba(246, 246, 246, 0.2);
            opacity: 1;
        }

        &.one-row {
            height: 36px;
        }

        &.two-rows {
            height: 46px;
        }

        &.helpCenter {
            background-color: #426076;
        }

        &.active {
            opacity: 1;
            background-color: rgba(246, 246, 246, 0.2);
            border-right: 4px solid #17B0F4;
        }

        &.disabled {
            opacity: 0.15;
        }

        &.placeholder:before {
            box-sizing: border-box;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 25px;
            height: 25px;
            margin: 20px;
            opacity: .5;
            background-color: white;
            border-radius: 5px;
        }
    }

    .manager-version {
        color: var(--white);
        opacity: 0.65;
        font-family: 'Cabin', sans-serif;
        font-size: 12px;
        text-align: center;
        width: 100%;
        padding-bottom: 8px;
        flex-shrink: 0;
    }

    .sunday-ticket-item {
        max-height: 95px;
        display: flex;
        justify-content: center;

        &.active {
            opacity: 1;
            background-color: rgba(246, 246, 246, 0.2);
            border-right: 4px solid #17B0F4;
        }
    }

    .sunday-ticket-item:hover {
        background-color: rgba(246, 246, 246, 0.2);
        opacity: 1;
    }
}

.SideBarClose,
.drawerPaperClose{
    width: 74px !important;
    overflow-x: hidden;

    @media (max-height: 760px) {
        width: 80px !important;
    }

    .everpass-logo {
        width: 50px;
        top: 20px;
    }
}

.drawerPaper {
    width: 172px;
    position: relative;
    white-space: nowrap;

    scrollbar-width: thin !important;

    background-color: var(--sidebar-header-secondary-color) !important;
    transition: var(--sidebar-transition) !important;
}

.drawerList {
    background-color: transparent !important;
    width: 172px;
    position: relative;
    white-space: nowrap;
}

.everpass-logo {
    width: 80px;
    top: 3px;
    margin-left: auto;
    margin-right: auto;
}

.wild-card {
    width: 135px;
}

.paramount {
    width: 105px;
    margin: 25px 0;
}