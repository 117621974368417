.Organization {
    box-sizing: border-box;
    flex: 1;
    display: flex;

    .header-container {
        justify-content: center;
        box-shadow: rgba(0, 0, 0, 0.12) 0 1px 6px, rgba(0, 0, 0, 0.12) 0 1px 4px;
        z-index: 10;
    }

    .main-container {
        flex: 1 1 auto;
        display: flex;
        flex-direction: column;

        .tab-navigator {
            box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
            z-index: 10;
        }

        .page-container {
            flex: 1 0 auto;
        }

    }

}