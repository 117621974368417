.input-container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-bottom: 2.625rem;

  input {
    font-family: sans-serif;
  }

  label {
    font-size: 14px;
  }

  div {
    display: block;
  }
  
  .button-container {
    display: flex;
    justify-content: space-between;
    
    button:disabled  {
        cursor: default;
        background-color: var(--dark-sky-blue);
        opacity: .45;
        color: white;
    }
  }
}
