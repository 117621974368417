.Dialog {
    font-family: 'Cabin', sans-serif;
    border-radius: 0;
    font-size: 14px;
    color: var(--dark-slate-blue);

    .close-button {
        display: flex;
        flex-direction: row-reverse;
    }

    .title {
        display: flex;
        flex-direction: column;
        padding: 0;
        margin-bottom: 16px;

        &.with-background {
            margin-top: 50px;
        }
    }

    .title-text {
        display: flex;
        font-family: 'Cabin', sans-serif;
        font-weight: normal;
        color: var(--dark-slate-blue);
        font-size: 28px;
        line-height: 37px;

        &.centered {
            text-align: center;
        }

        .centered {
            width: 100%;
            text-align: center;
        }
    }

    .title-close {
        align-self: flex-end;
        position: absolute;
        top: 12px;
        right: 12px;
    }

    .confetti-image {
        align-self: flex-start;
        position: absolute;
        top: 12px;
        width: 80%;
    }

    .content {
        padding: 0;
        overflow-x: hidden;
        color: var(--dark-slate-blue);
        margin-top: 20px;
        &.centered {
            text-align: center;
        }
    }

    .actions {
        display: flex;
        justify-content: space-between;
        margin: 24px 0 50px;

        &.centered {
            justify-content: center;
        }
    }
}