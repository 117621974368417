.App {
    flex: 1;
    display: flex;
    background: var(--background-color-lighter);

    @media (max-width: 960px) {
        .toastify--bottom-center {
            bottom: calc(1em + 70px);
        }
    }

    .WidgetLogin {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
