.MessageActionBar {
    width: 100%;
    height: auto;
    
    .container{
        width: 100%;
        height: 100%;
        background-color: var(--light-sky-blue);
        display: flex;
        flex-direction: row;
        align-items: center;
        box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0), 0 1px 1px 0 rgba(58, 172, 226, 0.1), 0 2px 1px -2px rgba(58, 172, 226, 0.1);
        padding-top: 24px;
        padding-bottom: 24px;
        box-sizing: border-box;
        cursor: default;

        .content {
            display: flex;
            gap: 0.625rem;
            color: var(--dark-slate-blue);
            padding-left: 2.5rem;
            padding-right: 1rem;
            
            .content-icon {
                margin-top: 0.5rem;
                width: 20px;
                .icon {
                    width: 20px;
                    height: 20px;
                    margin-left: -28px;
                }
            }

            .title-container {
                display: flex;
                flex-direction: column;
                gap: 0.5rem;
    
                .title-text {
                    font-size: 14px;
                    line-height: 18px;
                    font-weight: bold;
                }
            }
        
            .description {
                font-size: 14px;
                line-height: 15px;
                font-weight: 400;

                .link {
                    font-weight: bold;
                    text-decoration: underline;
                }
            }
        }

        .button {
            margin-left: auto;
            font-family: 'Cabin', sans-serif;
            margin-right: 32px;
            font-size: 14px;
            height: 50px;
            line-height: 18px;
            border-radius: 25px;
            padding: 0 32px;
            text-transform: none;
            box-shadow: none;
            min-width: max-content;
            white-space: nowrap;
        }

        .button-disable {	
            margin-left: auto;
            font-family: 'Cabin', sans-serif;
            font-size: 14px;
            height: 50px;
            line-height: 18px;
            border-radius: 25px;
            padding: 0 32px;
            text-transform: none;
            box-shadow: none;
            min-width: max-content;
            white-space: nowrap;
            color: var(--dark-slate-blue-60);
            background-color: rgba(58,172,226,0.1);
        }

        .close-color {
            color: var(--black-20);
            font-size: 20px;
        }

        .close {
            height: 100%;
            margin-left: 10px;

            &.withoutButton {
                margin-left: auto;
            }

            position: relative;
            right: 40px;
            top: -1rem;
        }

        &.alert {
            background-color: #FAEBED;

            .content {
                .content-icon {
                    color: var(--red-messages);
                }

                .description {
                    color: var(--dark-slate-blue);

                    .link {
                        &:hover {
                            color: var(--dark-slate-blue);
                        }
                    }
                }
            }

            .button {
                background-color: var(--red-messages);
            }
        }
    
    }

}