.ErrorView {
    flex: 1;
    max-width: 32em;
    margin: 42px auto;
    text-align: center;
    color: var(--dark-slate-blue);

    h2 {
        margin: .5em 0;
        font-size: 22px;
    }

    p {
        font-size: 14px;
        margin: .5em 0 1em;
        color: var(--dark-slate-blue-60)
    }

    .button {
        font-family: 'Cabin', sans-serif;
        font-size: 14px;
        height: 50px;
        line-height: 18px;
        border-radius: 25px;
        padding: 0 32px;
        text-transform: none;
        box-shadow: none;
    }
}