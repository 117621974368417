.ButtonCreateNew {
    display: flex;
    justify-content: center;

    .button {
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Cabin', sans-serif;
        color: var(--white);
        font-size: 12px;
        height: 34px;
        width: 125px;
        border-radius: 25px;
        border: none;
        background-color: var(--anchor-text-color);
        cursor: pointer;
        transition: width 0.3s linear;

        &:hover, &.button-menu-open {
            background-color: rgb(40, 120, 158);
        }

        &.round-button {
            width: 34px;
            min-width: 34px;
        }
    }
}

.lift-menu {
    position: absolute;
    top: -10px;
}

.CreateNewMenuList {
    font-family: 'Cabin', sans-serif;
    color: var(--white);
    font-size: 12px;
    width: 140px;
    position: absolute;
    top: 5px;
    background-color: var(--anchor-text-color);
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 5px;
    box-shadow: 0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12);

    .MenuItem, .SubMenuItem {
        display: flex;
        align-items: center;
        padding-left: 16px;
        height: 30px;
        cursor: pointer;
        
        .arrow-right {
            position: absolute;
            right: 4px;
            font-size: 16px;
        }

        &:hover {
            background-color: rgba(255, 255, 255, 0.28);
        }
    }
}