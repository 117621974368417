.LoadingOverlay {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.65);
    height: 100%;
    width: 100%;
    z-index: 5;

    .loader-background {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 120px;
        min-width: 155px;
        width: auto;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 6px;
        background-color: var(--background-color-lighter);
        box-shadow: 0 0 10px 0 var(--dark-slate-blue-10);

        .label {
            text-align: center;
            margin-bottom: 0;
            font-family: "Cabin", sans-serif;
            font-weight: bold;
            color: var(--dark-slate-blue);
            font-size: 14px;
            max-width: 171px;
        }
    }

    &.page {
        position: fixed;
        z-index: 20;
    }

    &.component {
        height: 100%;
        position: relative;

        .loader-background {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            box-shadow: none;
        }
    }
}