@import url('https://fonts.googleapis.com/css?family=Poppins:400,600');
// @TODO Try to use table components from material-ui
@import '~react-table-legacy/react-table.css';

:root {
    --text-color-over-light: rgba(0, 0, 0, .75);
    --text-color-over-light-secondary: rgba(0, 0, 0, .5);
    --text-color-over-dark-primary-15: rgba(255, 255, 255, .15);
    --text-color-over-dark-primary: rgba(255, 255, 255, .85);
    --text-color-over-dark-primary-65: rgba(255, 255, 255, 0.65);
    --text-color-over-dark-primary-4: rgba(255, 255, 255, 0.4);
    --text-color-over-dark-secondary: rgba(255, 255, 255, .6);
    --background-color-dark: #cdcdcd;
    --background-color-darker: #313131;
    --background-color-light: #f6f6f6;
    --background-color-lighter: #ffffff;
    --anchor-text-color: #3aace2;
    --light-cyan: #6cd0ff;
    --primary: #00A7F5;
    --primary-20: rgba(0, 167, 245, 0.20);
    --primary-05: rgba(0, 167, 245, 0.05);
    --background-color-red: #FAEBED;
    --text-color-red: #DE425B;


    --secondary: #ff0000;
    --accent: #21435F;
    --border-light: #e2e2e2;
    --border-dark: #d4d0d0;
    --green-dollar: #4c9513;
    --bright-sea-green: #58EDB9;
    --mint: #8BFFD7;
    --dark-mint: #57C7A0;
    --purple: #893AE2;
    --green-success: #00AE4C;
    //redesign
    --notification-light-red: #F96B81;
    --red-messages: #c2344a;
    --dark-red-messages: #FA6B6B;
    --light-red-messages: #FFE2E7;
    --red-messages-60: rgb(222, 66, 91, .6);
    --red-messages-10: rgb(222, 66, 91, .1);
    --dark-sky-blue: var(--anchor-text-color);
    --white: #ffffff;
    --white-two: #f8f8f8;
    --brownish-grey: #707070;
    --brownish-grey-two: #676767;
    --brownish-grey-25: rgb(103, 103, 103, 0.25);
    --brownish-grey-50: rgb(103, 103, 103, 0.5);
    --dark-slate-blue: rgba(33, 68, 94, 1); //#21445e
    --dark-slate-blue-05: rgba(33, 68, 94, 0.05);
    --dark-slate-blue-10: rgba(33, 68, 94, 0.1);
    --dark-slate-blue-20: rgba(33, 68, 94, 0.2);
    --dark-slate-blue-40: rgba(33, 68, 94, 0.4);
    --dark-slate-blue-50: rgba(33, 68, 94, 0.5);
    --dark-slate-blue-60: rgba(33, 68, 94, 0.6);
    --dark-slate-blue-70: rgba(33, 68, 94, 0.7);
    --dark-sky-blue-10: rgba(58, 172, 226, 0.1);
    --dark-sky-blue-80: rgba(58, 172, 226, 0.8);
    --dark-sky-blue-65: rgba(58, 172, 226, 0.65);
    --very-light-gray: #e9e6e6;
    --light-sky-blue: #ebf6fc;
    --black-20: rgba(0, 0, 0, 0.2);
    --light-gray: #f6f6f6;
    --light-gray-2: #E4E4E5;
    --light-gray-3: #d8d8d8;
    --gray: #9FA1AB;
    --blue-shadow: rgba(33, 68, 94, 0.1);

    --dark-cyan: #288bb9;
    --yellow-message: #FFBE00;
    --yellow-message-10: rgba(255, 190, 0, 0.1);
    --light-yellow-message: #FFE5B2;

    --canva-button: #24BECA;
    --input-border: #656979;

    --blue-status-tag: #00A2AB;
    --orange-status-tag: #F77B12;
    --red-status-tag: #DE425B;
    --red-text: #E50914;
   
}

body,
html {
    margin: 0;
    padding: 0;
    height: 100%;
    background: white !important;
    font-family: 'Cabin', sans-serif;
    font-size: 100%;
    color: var(--text-color-over-light);
}

#root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

a:link {
    color: var(--anchor-text-color);
    text-decoration: none;
}

a:visited {
    color: var(--anchor-text-color);
    text-decoration: none;
}

a:hover {
    color: var(--anchor-text-color);
    text-decoration: underline;
}

a:active {
    color: var(--anchor-text-color);
    text-decoration: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid var(--border-light);
    margin: 1em 0;
    padding: 0;
}

.Toastify {
    .Toastify__toast-container {
        width: auto;
        min-width: 360px;

        .Toastify__toast--success {
            background-color: #00AE4C;
        }

        .Toastify__toast--error {
            background-color: #DE425B;
        }

        .Toastify__toast {
            border-radius: 5px;
            padding: 0 24px;

            .Toastify__toast-body {
                display: flex;
                align-items: center;

                svg {
                    margin-right: 8px;
                }
            }

            .Toastify__close-button {
                opacity: 1;
                align-self: center;
                margin-left: 16px;
            }
        }
    }
}

@media (min-width: 961px) {
    .Organization .footer-container {
        order: 2;
    }
    .Organization .page-container {
        order: 3;
    }
}

.main-scroll-container {
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100%;
    flex-direction: column;
}

.App .ReactTable {
    border: none;
    background-color: var(--background-color-lighter);
    font-size: 12px;

    .rt-thead {

        &.-header {
            box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.1);
        }

        .rt-th, .rt-td {
            padding: 1.2em 0;

            &:focus {
                outline: none;
            }

            &.-sort-desc {
                box-shadow: inset 0 -3px 0 0 var(--primary);
            }

            &.-sort-asc {
                box-shadow: inset 0 3px 0 0 var(--primary);
            }
        }

    }

    .rt-tbody {

        .rt-td {
            padding: 1.2em 1em;
        }

        .rt-tr {
            &:hover {
                background-color: var(--background-color-light);
            }
        }

    }

    .-pagination {

        .-center {
            justify-content: center;
        }

        .-btn {
            font-size: 12px;
            font-weight: 700;
            color: var(--text-color-over-dark-primary);
            background-color: var(--primary);

            &:not([disabled]):hover {
                color: var(--text-color-over-dark-primary);
                background-color: var(--primary);
                opacity: .9;
            }
        }
    }
}

.spinner {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(-360deg);
        transform: rotate(-360deg);
    }
}

$screen-lg-min: 1425px;
$screen-actions-min: 1700px;
@mixin lg-breakpoint {
    @media (max-width: #{$screen-lg-min}) {
        @content
    }
}

@mixin schedule-breakpoint {
    @media (max-width: #{$screen-actions-min}) {
        @content
    }
}

