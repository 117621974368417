.OktaSignInWidget {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

.svg-logo {
  margin-bottom: 20px;
  margin-top: 20px;
}

.redirect-wrapp {
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 20px;
  margin-top: 8%;
}

.text-redirect {
  font-weight: bold;
  color: black;
  font-size: 28px;
}

.button-redirect {
  background-color: #5bb5e9;
  border: none;
  color: white;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  border-radius: 20px;
  cursor: pointer;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #5bb5e9;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
