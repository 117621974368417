.EverpassSideBar {
    --sidebar-header-text-color: #9D9D9D;
    --sidebar-subheader-text-color: #C4C4C4;
    --sidebar-subheader-active-text-color: #C4C4C4;
    --sidebar-bg-color: #212121;
    --sidebar-transition: width 0.1s linear;
    transition: var(--sidebar-transition);
    font-family: 'Cabin', sans-serif;
    color: var(--sidebar-subheader-text-color);
    width: 208px;
    z-index: 100;

    .header-toggle-btn {
        width: 40px;
        height: 40px;
        padding: 8px;

    }

    .header-toggle-icon {
        color: var(--sidebar-subheader-text-color);
        width: 24px;
        height: 24px;
    }

    .DrawerHeader {
        height: 56px;
        display: flex;
        justify-content: center;
        align-items: center;

        &.toggle-left {
            justify-content: flex-start;
        }
    }

    .DrawerContentContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
    }

    .DrawerContent {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }

    .DrawerList {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;

        &.addGap {
            gap: 4px;
        }
    }

    .open {
        justify-content: flex-end;
        padding-right: 0;
    }

    .textBelow {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-bottom: 22px;
    }

    .menu-item {
        box-sizing: border-box;
        align-items: center;
        height: 40px;

        &.item-open{
            margin-bottom: 8px;
            height: 48px;
        }

        .list-item-icon {
            min-width: 30px;

            &.open {
                margin: 0;
            }
        }

        &.textBelow {
            .list-item-icon {
                margin: 0px;
                min-width: unset;
            }
        }

        .assets-icon {
            fill: var(--sidebar-subheader-text-color);
        }
        .iconColor {
            color: var(--sidebar-subheader-text-color);
            margin-left: -3px;
        }

        .iconText {
            font-family: 'Cabin', sans-serif;
            color: var(--sidebar-subheader-text-color);
            font-size: 14px;
            margin-left: 15px;
        }
        
        .iconTextClosed {
            font-family: 'Cabin', sans-serif;
            color: var(--sidebar-subheader-text-color);
            font-size: 12px;
        }

        &:focus,
        &:hover {
            background-color: rgba(246, 246, 246, 0.2);
            border-radius: 100px;
            opacity: 1;

            .assets-icon {
                fill: #E9E9E9;
            }

            .iconText {
                color: #E9E9E9;
    
                .indicator {
                    color: #E9E9E9;
                }
    
            }
        }

        &.helpCenter {
            background-color: #426076;
        }

        &.active {
            opacity: 1;
            background-color: #1F4F41;
            border-radius: 100px;
            border-right: unset;

            .assets-icon {
                fill: #E9E9E9;
            }


            .iconText {
                color: #E9E9E9;
    
                .indicator {
                    color: #E9E9E9;
                }
    
            }
        }

        &.disabled {
            opacity: 0.15;
        }
    }

    .sunday-ticket-item {
        max-height: 95px;
        display: flex;
        justify-content: center;

        &.active {
            opacity: 1;
            background-color: #1F4F41;
            border-radius: 100px;
            border-right: unset;
        }
    }

    .sunday-ticket-item:hover {
        background-color: rgba(246, 246, 246, 0.2);
        opacity: 1;
    }
}

.EverpassDrawerPaper {
    top: unset !important;
    width: 176px;
    white-space: nowrap;
    padding-left: 16px;
    padding-right: 16px;
    scrollbar-width: thin !important;

    background-color: var(--sidebar-bg-color) !important;
    transition: var(--sidebar-transition) !important;
}

.EverpassSideBarClose{
    width: 80px !important;
}
.EverpassDrawerPaperClose{
    top: unset !important;
    overflow-x: hidden;
    width: 64px !important;
    padding-left: 8px;
    padding-right: 8px;
}

.DrawerList {
    .subHeader {
        color: var(--sidebar-header-text-color);
    }
}


.divider{
    height: 0;
    color: var(--sidebar-header-text-color);
    margin-top: 16px;
}