.search-form {
    position: relative;
    display: flex;
    align-items: center;

    .search-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 48px;
        width: 24px;
        border-radius: 8px;
        border: 1px transparent;
        transition: width 0.3s ease-in-out, background-color 0.1s ease-in-out, border 0.3s ease-in-out;
        overflow: hidden;
    }

    .search-container.open {
        width: 320px;
        padding: 0 16px;
        border: 1px solid var(--grey-40);
        background-color: var(--grey-90);
    }

    .search-icon {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none;
        border: none;
        color:white;
        padding: 0;
    }

    .icon {
        color: #888;
        transition: color 0.2s ease-in-out;
    }

    .search-input {
        flex: 1;
        background-color: transparent;
        height: 3rem;
        padding-left: 1.25rem;
        border-width: 0;
        border-color: transparent;
        outline: none;
        font-size: 14px;
        color: white;
        font-weight: 500;
 
    }
    .search-input::placeholder {
        color: var(--grey-50);
    }

    .icon:hover {
        color: #000;
    }

    .clear-icon:hover {
        display: flex;
        align-items: center;
        cursor: pointer;
        background: none;
        border: none;
        color:white;
        padding: 0;
    }
}
