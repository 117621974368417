.Organizations {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;

    .no-orgs {
        flex: 1;
        display: flex;
        justify-content: center;
        align-content: center;
        align-items: center;
        flex-wrap: wrap;

        h2 {
            text-align: center;
        }

        svg {
            width: 100%;
        }
    }
}