.BreadcrumbDropdown {
    .ddOpen, .ddClose {
        display: flex;
        justify-content: center;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        margin-right: 5px;
        border-radius: 3px;
        border: solid 1px rgba(33, 68, 94, 0.2);
        background-color: var(--light-slate-blue);
        color: var(--dark-slate-blue);
        cursor: pointer;

        &:hover {
            color: var(--dark-sky-blue);
        }

        &.blue {
            background-color: var(--dark-sky-blue);
            color:var(--white);

            &:hover {
                color: var(--dark-slate-blue);
            }

            &:disabled {
                color: var(--light-gray-3);
                cursor: default;
            }
        }
    }
}

.DropdownMenuItem-highlighted {
    background-color: var(--light-sky-blue);
}

.BreadcrumbDropdown-SubHeader {
    font-size: 10px;
    line-height: 12px;
    outline: none;
    color: var(--dark-slate-blue-60);
    cursor: default;
    font-weight: 600;
    display: inline-block;
    padding: 5px 12px 7px;
}

.DropdownLink {
    &:hover {
        text-decoration: none;
    }
}

.DropdownMenuItem, .DropdownSubmenuItem {
    display: flex;
    align-items: center;
    font-family: 'Cabin', sans-serif;
    font-size: 12px;
    font-weight: 600;
    height: 36px;
    min-height: 36px;
    padding: 0 12px;
    cursor: pointer;
    color: var(--dark-slate-blue);
    min-width: 150px;
    max-height: 400px;

    .arrow-right {
        color: var(--black-20);
        font-size: 20px;
    }

    a {
        width: 100%;
        color: var(--dark-slate-blue);

        &:visited {
            color: var(--dark-slate-blue);
        }

        &:link {
            color: var(--dark-slate-blue);
        }
    }

    .list-icon {
        margin-right: 5px;
        font-size: 18px;
    }

    &:hover {
        background-color: var(--light-sky-blue);
        color: var(--dark-sky-blue);

        a {
            text-decoration: none;
            color: var(--dark-sky-blue);
        }

        .list-icon {
            color: var(--dark-sky-blue);
        }
    }

    &.ddSelected {
        color: var(--white);
        background-color: var(--dark-sky-blue);

        a {
            color: var(--white);
        }

        .list-icon {
            color: var(--white);
        }
    }
}

.BreadcrumbMenuList {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 400px;
}